import { useWebWallet } from '@/context/WebWalletContext';
import { getTXUrl } from '@particle-network/wallet-core';
import { Button } from 'antd';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import WModel from '../WModel';
import MIcon from '../mIcon';
interface IProps {
  onClose: () => void;
}
const TransactionSuccessModel = forwardRef((props: IProps, ref) => {
  const wModel = useRef<any>(null);
  const { t, webWalletData } = useWebWallet();
  const [tx, setTx] = useState<string>('');

  useImperativeHandle(ref, () => ({
    setVisible: (visible: boolean, tx: string) => {
      setTx(tx);
      wModel.current?.setVisible(visible);
    },
  }));

  const detailUrl = useMemo(() => {
    if (webWalletData.isLogin && webWalletData.chainInfo.chainName && tx) {
      if (/^http/.test(tx)) {
        return tx;
      }
      return getTXUrl({
        chainType: 'evm',
        chainId: webWalletData.chainInfo.chainId,
        chainName: webWalletData.chainInfo.chainName,
        hash: tx,
      });
    } else {
      return '';
    }
  }, [webWalletData.isLogin, webWalletData.chainInfo.chainName, tx]);

  return (
    <WModel ref={wModel} title="" onClose={props?.onClose} modalProps={{ width: 420 }}>
      <div className="transaction-result-container">
        <div className="title">{t('transaction')}</div>
        <div className="content">{t('the_transaction_has_d4343c4e')}</div>
        <div className="btns" key={detailUrl}>
          <Button
            type="primary"
            onClick={() => {
              window.open(detailUrl);
            }}
          >
            <MIcon name="icon_52" size={16} />
            <span>{t('blockchain_browser')}</span>
          </Button>
        </div>
        <div className="footer">
          <div className="logo"></div>
        </div>
      </div>
    </WModel>
  );
});

export default TransactionSuccessModel;
