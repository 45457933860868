import { BuyButton } from '@/components/buy/index';
import Header from '@/components/header';
import { SwapButton } from '@/components/index';
import Loading from '@/components/loading';
import { MiniLink } from '@/components/miniLink';
import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { useWebWallet } from '@/context/WebWalletContext';
import useTokensAndNFTsRequest from '@/hooks/useTokensAndNFTsRequest';
import useTransactions from '@/hooks/useTransactions';
import { PageType } from '@/types/index';
import { errorHandle, getCurrencySymbol, isServer } from '@/utils/index';
import { TokenInfo, WSOL_TOKEN_ADDRESS, defaultTokenIcon, formatAmount, formatPng, getCacheTokenList, getScanUrl, toNonExponential } from '@particle-network/wallet-core';
import { useRequest } from 'ahooks';
import { Drawer, Image, Popover, Tabs, message } from 'antd';
import { useRouter } from 'next/router';
import qs from 'qs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReceiveIconButton, SendIconButton, WithdrawIconButton } from '../IconButtons';
import ReceiveAddress from '../index/modules/receiveAddress';
import MIcon from '../mIcon';
import ActivityList from './activityList';
import ActivityList2 from './activityList2';

export enum TokenDetailPageMode {
  Default = 'default',
  Simple = 'simple',
}

export enum TokenDetailTabKeys {
  All = 'All',
  Out = 'Out',
  In = 'In',
  Failed = 'Failed',
}

const HideTokenContent = (props: any) => {
  const { hideThisTokenRequest } = props;
  const router = useRouter();
  const { webWalletData, t } = useWebWallet();

  return (
    <div className="drawer-token">
      <div
        className="drawer-token-item"
        onClick={() => {
          hideThisTokenRequest();
        }}
      >
        <div className="drawer-token-icon hide_token">
          <MIcon name="icon_69" size={40} />
        </div>
        {t('hide_this_token')}
      </div>
      <div
        className="drawer-token-item"
        onClick={() => {
          const url = getScanUrl({
            chainType: webWalletData.chainInfo.chainType,
            chainId: webWalletData.chainInfo.chainId,
            chainName: webWalletData.chainInfo.chainName,
            chainNetwork: webWalletData.chainInfo?.chainNetwork.toLocaleLowerCase(),
            address: router.query.tokenAddress,
          });
          if (url) {
            window.open(url, '_blank');
          }
        }}
      >
        <div className="drawer-token-icon token_info">
          <MIcon name="icon_68" size={40} />
        </div>
        {t('token_info')}
      </div>
      <div className="footer-logo"></div>
    </div>
  );
};

export const amountView = (item: any, webWalletData: any) => {
  if (webWalletData.chainInfo.chainType == 'evm') {
    let amount: string = (item.value == '0' || item.value == '' || item.value == undefined ? '' : `${item.action == 'in' ? '+' : '-'}${item.value}`.replace('--', '-')).trim();
    if (amount) {
      amount += ' ' + (item.amountSymbol || item.symbol || webWalletData.chainInfo.symbol);
    }
    amount = amount?.trim?.() || '';

    return (amount?.split?.(' ')?.[0]?.substring?.(0, 16) || '') + ' ' + (amount?.split?.(' ')?.[1] || '');
  } else {
    let amount: string = '';
    if (item?.data?.amountTransfered) {
      amount = toNonExponential(item.data.amountTransfered / 10 ** (item.data.decimals || 0));
    }
    if (amount && amount != '0') {
      amount += ' ' + (item?.data?.symbol || item.symbol || webWalletData.chainInfo.symbol);
    } else {
      amount = item.type == 'unknown' ? '0' : item.value == '0' ? '' : `${item.value || ''}` + ' ' + (item?.data?.symbol || item.symbol || webWalletData.chainInfo.symbol);
    }
    if (amount != '0') {
      amount = `${item.action == 'in' ? '+' : '-'} ${amount}`.replace('--', '-');
    } else {
      amount = '';
    }
    amount = amount?.trim().replace('+ -', '- ');
    if (amount == '+' || amount == '-') {
      return '';
    }
    return amount;
  }
};

const TokenDetail = (props: any) => {
  const router = useRouter();

  const { webWalletData, t } = useWebWallet();

  const [tabActiveKey, setTabActiveKey] = useState<TokenDetailTabKeys>(TokenDetailTabKeys.All);

  const receiveAddressRef = useRef<any>();

  const [tabList] = useState<Array<any>>(Object.values(TokenDetailTabKeys).map((val: string) => ({ tabName: val })));

  const [tokenModal, setTokenModal] = useState(false);

  const [tokenAddress, setTokenAddress] = useState('');

  const { tokenList, tokensAndNFTsRequest, tokensAndNFTsRefresh } = useTokensAndNFTsRequest();

  const { uaBalance, selectedGlobalChain, universalTokens, universalTransactionList, universalTransactionLoading } = useUniversalAccount();

  const amountRef = useRef(null);

  const pageMode = useMemo(() => {
    if (isServer()) return TokenDetailPageMode.Default.toLowerCase();
    // @ts-ignore
    const pageMode = (qs.parse(window.location.search.split('?').pop(), { ignoreQueryPrefix: true }).pageMode || TokenDetailPageMode.Default).toLowerCase();
    return pageMode;
  }, []);

  const { loading: withdrawLoading, run: withdrawRequest } = useRequest(
    () => {
      return webWalletData.particleWallet.withdraw();
    },
    {
      manual: true,
      onError: (err) => {
        errorHandle(err);
      },
      onSuccess: (res) => {
        tokensAndNFTsRefresh();
        message.success(t('withdraw_success'));
      },
    }
  );

  const {
    data: transactionList = [],
    run: getTransactionsList,
    cancel: cancelGetTransactionsList,
    loading: transactionLoading,
  } = useTransactions({
    pollingInterval: 10000,
    pollingWhenHidden: false,
  });

  const newTransactionList = useMemo(() => {
    if (selectedGlobalChain) {
      return universalTransactionList;
    } else {
      return transactionList;
    }
  }, [selectedGlobalChain, transactionList, universalTransactionList]);

  const newTransactionLoading = useMemo(() => {
    if (selectedGlobalChain) {
      return universalTransactionLoading;
    } else {
      return transactionLoading;
    }
  }, [selectedGlobalChain, transactionLoading, universalTransactionLoading]);

  /**
   * 是否支持 Bridge
   */
  const isSupportBridge = useMemo(() => {
    if (selectedGlobalChain) {
      return false;
    }
    return webWalletData.supportBridgeChains?.includes(Number(webWalletData.chainId));
  }, [webWalletData.supportBridgeChains, webWalletData.chainId, selectedGlobalChain]);

  const { data: exchangeRateData, run: getPrice } = useRequest(
    (tokenAddress: string, useCache: boolean = true) => {
      return webWalletData?.particleWallet?.getPrice?.([tokenAddress], useCache).then((list: any[]) => {
        return list.find((item) => item.address?.toLowerCase?.() === tokenAddress?.toLowerCase?.()) || {};
      });
    },
    { manual: true }
  );

  const { run: hideThisTokenRequest } = useRequest(
    async () => {
      const list = await webWalletData.particleWallet?.getImpoertTokenList({ userAddress: webWalletData.address?.toLowerCase(), chainNetwork: webWalletData.chainInfo.chainNetwork });
      const tokenAddress = ((router?.query?.tokenAddress || '') as string).toLowerCase();
      const tokenData = list.find((item: any) => item.tokenAddress?.toLowerCase() == tokenAddress);
      if (tokenData) {
        tokenData.isHide = true;
        tokenData.isSelected = false;
        return webWalletData.particleWallet.addImportTokenData(tokenData);
      } else {
        return Promise.reject('token not found');
      }
    },
    {
      manual: true,
      onSuccess: (res) => {
        message.success(t('the_token_has_been_hidden'));
        router.replace('/');
      },
      onError: (err) => {
        errorHandle(err);
      },
    }
  );

  const getTabLabel = useCallback((value) => {
    const label = t(value.toLowerCase());
    return label;
  }, []);

  const tokenType = useMemo(() => {
    if (!selectedGlobalChain) {
      return '';
    }
    return (router?.query?.tokenType as unknown as string) || 'eth';
  }, [router, selectedGlobalChain]);

  const tokenData: TokenInfo = useMemo(() => {
    try {
      if (selectedGlobalChain) {
        const token = universalTokens?.find((item: TokenInfo) => item.tokenType === tokenType);
        if (token) {
          return token;
        } else {
          return {};
        }
      } else {
        const _tokenList = tokenList?.length ? tokenList : getCacheTokenList();
        let tokenData = (_tokenList || []).find((item: TokenInfo) => item.address?.toLowerCase?.() == tokenAddress?.toLowerCase?.()) || {};
        if (exchangeRateData && typeof exchangeRateData.price !== 'undefined') {
          tokenData.price = (tokenData.amount || 0) * exchangeRateData.price;
        }
        return tokenData;
      }
    } catch (error) {}
    return {};
  }, [tokenList, tokenAddress, exchangeRateData, selectedGlobalChain, tokenType, selectedGlobalChain, universalTokens]);

  useEffect(() => {
    if (webWalletData.isLogin && router.pathname === '/tokenDetail') {
      webWalletData.clearPageCache(PageType.Send);
      webWalletData.clearPageCache(PageType.Swap);
      webWalletData.clearPageCache(PageType.Bridge);
      let tokenAddress = router.asPath.match(/tokenAddress=(\w+)/)?.[1] || '';
      setTokenAddress(tokenAddress);
      getPrice(tokenAddress);
      tokensAndNFTsRequest();
    }
  }, [webWalletData.isLogin, webWalletData.particleWallet, router.pathname]);

  useEffect(() => {
    // 获取元素宽度(amountRef.current) 是否超过 body 的宽度，超过则降低字体大小，直到不超过为止
    if (amountRef.current && tokenData.amount) {
      let flag = true;
      do {
        const amountDom = amountRef.current as HTMLDivElement;
        const bodyDom = document.body;
        const amountDomWidth = amountDom.offsetWidth;
        const bodyDomWidth = bodyDom.offsetWidth;
        const amountDomFontSize = parseFloat(window.getComputedStyle(amountDom).fontSize);
        if (amountDomWidth >= bodyDomWidth - 40) {
          amountDom.style.fontSize = amountDomFontSize - 2 + 'px';
        } else {
          flag = false;
        }
      } while (flag);
    }
  }, [amountRef.current, tokenData.amount]);

  useEffect(() => {
    if (webWalletData.isLogin && tokenAddress) {
      cancelGetTransactionsList();
      getTransactionsList(tokenAddress);
    }
  }, [webWalletData.isLogin, tokenAddress, webWalletData.particleWallet]);

  return (
    <div className="token-detail-container scrollbar" data-page-mode={pageMode}>
      <div className="wrapper" data-page-mode={pageMode}>
        {pageMode === TokenDetailPageMode.Simple && (
          <>
            <Header
              hiddenBackButton={true}
              displayDoubleArrows={true}
              onBack={() => {
                window.close();
              }}
              title={<div className="header-title">{t('transaction_activity')}</div>}
            />
          </>
        )}

        {pageMode === TokenDetailPageMode.Default && (
          <Header
            title={
              <div className="header-title">
                {!!tokenData.image && (
                  <>
                    <Image className="icon" src={formatPng(tokenData.image)} key={tokenData.image} fallback={defaultTokenIcon} preview={false} />
                    <span>{tokenData.symbol || tokenData?.address?.substr(0, 5)}</span>
                  </>
                )}
              </div>
            }
            rightContext={
              !selectedGlobalChain &&
              router.query.tokenAddress !== 'native' &&
              !((router.query.tokenAddress || '') as string).toLowerCase?.().includes('sol') &&
              (webWalletData.viewType == 'desktop' ? (
                <Popover
                  placement="bottomRight"
                  overlayClassName="hide-token-popover"
                  content={<HideTokenContent hideThisTokenRequest={hideThisTokenRequest} />}
                  getPopupContainer={() => {
                    return document.querySelector('.token-detail-container>.wrapper') as HTMLElement;
                  }}
                  trigger="click"
                >
                  <div className="action-bar">
                    <MIcon name="icon_77" size={30} />
                  </div>
                </Popover>
              ) : (
                <div onClick={() => setTokenModal(true)} className="action-bar">
                  <MIcon name="icon_77" size={30} />
                </div>
              ))
            }
          />
        )}

        <div className="main-content scroll-content-1">
          {pageMode === TokenDetailPageMode.Default && (
            <div className="wallet-info-content">
              <div className="account-info-Nx9">
                <div className="row row1">
                  <span ref={amountRef}>{formatAmount(tokenData.amount, 9)}</span>
                </div>
                {
                  <div className="row row2">
                    ≈{getCurrencySymbol(webWalletData.currency)}
                    {formatAmount((tokenData?.price || '0').toString().replace(/\,/g, '') || 0, 4) || '0'}
                  </div>
                }
              </div>
              <div className="operate-content">
                {tokenAddress?.toLowerCase?.() == WSOL_TOKEN_ADDRESS?.toLowerCase?.() ? (
                  <div
                    className="item"
                    onClick={() => {
                      withdrawRequest();
                    }}
                  >
                    {/* <MIcon name="icon_6" /> */}
                    <WithdrawIconButton />
                    <div className="name">{t('withdraw')}</div>
                  </div>
                ) : (
                  <MiniLink href={`/send.html?tokenAddress=${tokenAddress}&tokenType=${(router?.query?.tokenType as unknown as string) || ''}`}>
                    <div className="item">
                      {/* <MIcon name="icon_7" /> */}
                      <SendIconButton />
                      <div className="name">{t('send')}</div>
                    </div>
                  </MiniLink>
                )}
                <div
                  className="item"
                  onClick={() => {
                    if (receiveAddressRef.current) {
                      receiveAddressRef.current?.setVisible(true);
                    }
                  }}
                >
                  {/* <MIcon name="icon_8" /> */}
                  <ReceiveIconButton />
                  <div className="name">{t('receive')}</div>
                </div>
                <BuyButton loading={false} />
                <SwapButton loading={false} />

                {!selectedGlobalChain && (
                  <>
                    {/* Bridge Button */}
                    <div
                      className={`item ${isSupportBridge ? '' : 'disabled'}`}
                      onClick={() => {
                        if (webWalletData.supportBridgeChains.includes(Number(webWalletData.chainId))) {
                          webWalletData.clearPageCache(PageType.Bridge);
                          router.push('/bridge.html');
                        }
                      }}
                    >
                      <div className="icon-button-default">
                        <MIcon name="icon_55" size={40} />
                      </div>
                      <div className="name">{t('bridge')}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          <div className="list-content">
            <Tabs
              activeKey={tabActiveKey}
              className={`page-mode-${pageMode.toLowerCase()}`}
              onChange={(tabActiveKey) => {
                setTabActiveKey(tabActiveKey as TokenDetailTabKeys);
              }}
              items={tabList.map((tabItem: any, tabIndex) => {
                return {
                  label: getTabLabel(tabItem.tabName),
                  key: tabItem.tabName,
                  children:
                    webWalletData.chainInfo.chainType === 'solana' ? (
                      <ActivityList tabActiveKey={tabActiveKey} pageMode={pageMode} transactionList={newTransactionList} transactionLoading={newTransactionLoading} />
                    ) : (
                      <ActivityList2 tabActiveKey={tabActiveKey} pageMode={pageMode} transactionList={newTransactionList} transactionLoading={newTransactionLoading} />
                    ),
                };
              })}
            />
          </div>
          <ReceiveAddress ref={receiveAddressRef} address={router.query.tokenAddress} />
        </div>
      </div>
      <Loading spinning={withdrawLoading} className="page-loading"></Loading>

      <Drawer closable={false} placement="bottom" onClose={() => setTokenModal(false)} open={tokenModal}>
        <HideTokenContent hideThisTokenRequest={hideThisTokenRequest} />
      </Drawer>
    </div>
  );
};

export default TokenDetail;
