import MIcon from '@/components/mIcon';
import { TransactionsType, defaultTokenIcon } from '@particle-network/wallet-core';
import { Image } from 'antd';
import styles from './index.module.scss';

const iconMap: any = {
  [TransactionsType.RSS3_NATIVE_SEND]: 'icon_transaction_send',
  [TransactionsType.RSS3_NATIVE_RECEIVE]: 'icon_transaction_receive',
  [TransactionsType.NATIVE_SEND]: 'icon_transaction_send',
  [TransactionsType.NATIVE_RECEIVE]: 'icon_transaction_receive',
  [TransactionsType.RSS3_TOKEN_SEND]: 'icon_transaction_send',
  [TransactionsType.RSS3_TOKEN_RECEIVE]: 'icon_transaction_receive',
  [TransactionsType.RSS3_ERC_20_APPROVE]: 'icon_transaction_approve',
  [TransactionsType.RSS3_ERC_20_BURN]: 'icon_transaction_burn',
  [TransactionsType.RSS3_NFT_SEND]: 'icon_transaction_send',
  [TransactionsType.RSS3_NFT_RECEIVE]: 'icon_transaction_receive',
  [TransactionsType.RSS3_NFT_APPROVE]: 'icon_transaction_send',
  [TransactionsType.RSS3_NFT_MINT]: 'icon_transaction_mint',
  [TransactionsType.RSS3_NFT_BUY]: 'icon_transaction_buy',
  [TransactionsType.RSS3_SWAP]: 'icon_transaction_swap',
  [TransactionsType.CHECK_IN]: 'icon_transaction_check_in',
  [TransactionsType.UNKNOWN]: 'icon_transaction_default',
};

interface Props {
  type?: TransactionsType;
  badge?: string;
}

const ActivityIcon = ({ type = TransactionsType.UNKNOWN, badge }: Props) => {
  const iconName = iconMap[type?.toLowerCase()] || iconMap[TransactionsType.UNKNOWN];

  return (
    <div className={styles.iconWrap} data-icon-name={iconName} data-type={type}>
      <MIcon name={iconName} size={50} />
      {badge && (
        <div className={styles.badge}>
          <Image src={badge} alt="" fallback={defaultTokenIcon} preview={false} />
        </div>
      )}
    </div>
  );
};

export default ActivityIcon;
