import Header, { HeaderType } from '@/components/header';
import { MiniLink } from '@/components/miniLink';
import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { useWebWallet } from '@/context/WebWalletContext';
import useAllTokens from '@/hooks/useAllTokens';
import { getSecurityTokenAddressList } from '@/hooks/useTokensAndNFTsRequest';
import { getCurrencySymbol, isServer } from '@/utils/index';
import { ImportTokenInfo, defaultTokenIcon } from '@particle-network/wallet-core';
import { useDebounceEffect, useRequest, useVirtualList } from 'ahooks';
import { Image, Switch } from 'antd';
import { useRouter } from 'next/router';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MIcon from '../mIcon';
import ScrollContainer from '../scrollContainer';
import SkeletonList from '../skeletonList';
import TokenIcons from '../tokenIcons';

export const TokenList = (props: any) => {
  const { webWalletData, updateWebWalletData } = useWebWallet();

  const containerRef = useRef(null);

  const { t } = useTranslation();

  const { selectedGlobalChain } = useUniversalAccount();

  const wrapperRef = useRef(null);

  const router = useRouter();

  const [list] = useVirtualList(props.dataList, {
    containerTarget: containerRef,
    wrapperTarget: wrapperRef,
    itemHeight: isServer() || location.pathname == '/' ? 60 : 60,
    overscan: isServer() || location.pathname == '/' ? 99999 : 200,
  });

  const isIndex = useMemo(() => {
    if (isServer()) {
      return true;
    }
    if (webWalletData.isLogin) {
      return location.pathname == '/';
    } else {
      return false;
    }
  }, [webWalletData.isLogin, router.pathname]);

  return (
    <div className="token-list" ref={containerRef}>
      <div className="wrap" ref={wrapperRef}>
        {(isIndex
          ? props.dataList.map((o: any, index: number) => {
              return { data: o, index };
            })
          : list
        ).map((item: any) => {
          return item?.data?.nodeType == 'title' ? (
            <div className={'list-' + item.data.titleKey} key={item.data.title.replace(/\s/g, '-')}>
              {item.data.title}
            </div>
          ) : (
            <div
              className={'token-item item-' + item.data.address}
              key={`${webWalletData.chainName}_${item?.data?.index || item.index}_${item?.data?.symbol}_${item?.data?.name}`.replace(/\s/g, '')}
              data-key={`${webWalletData.chainName}_${item?.data?.index || item.index}_${item?.data?.symbol}_${item?.data?.name}`.replace(/\s/g, '')}
              onClick={() => {
                props.itemClick && props.itemClick(item.data, item.data.address);
              }}
            >
              <div className="left">
                <Image
                  className="token-icon"
                  src={item.data.image || item.data.logoURI || defaultTokenIcon}
                  key={(item.data.image || item.data.logoURI || '') + item.index}
                  fallback={defaultTokenIcon}
                  preview={false}
                />
                <div className="wrap-name">
                  <div className="name">{item.data.symbol || item.data.name || item.data?.address?.substr?.(0, 5) || item.data?.tokenAddress?.substr?.(0, 5)}</div>
                  {item.data?.chains && selectedGlobalChain ? (
                    <TokenIcons tokens={item.data?.chains || []} />
                  ) : isIndex && (item.data.unitPrice || item.data['24hChange']) ? (
                    <div className="item-left-row2">
                      {item.data.unitPrice ? (
                        <div className="unit-price">
                          {getCurrencySymbol(webWalletData.currency)}
                          {item.data.unitPrice}
                        </div>
                      ) : (
                        ''
                      )}
                      {item.data['24hChange'] ? (
                        <div className={'riseee ' + (item.data['24hChange'] <= 0 ? 'negative' : '')}>
                          <div className="hidden">{JSON.stringify(item, null, 2)}</div>
                          {item.data['24hChange'] < 0 ? '' : item.data['24hChange'] > 0 ? '+' : ''}
                          {item.data['24hChange']}%
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <div className="item-left-row3"></div>
                  )}
                </div>
              </div>
              <div className="right">
                <div className="wrap">{props.children ? props.children(item.data) : ''}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Page = () => {
  const router = useRouter();

  const { webWalletData, updateWebWalletData } = useWebWallet();

  const [loading, setLoading] = useState(true);

  const { data: allTokenList } = useAllTokens();

  const { t } = useTranslation();

  const [requestDate, setRequestDate] = useState(0);

  // tokenList
  const { data: tokenList = [], run: requestTokenList } = useRequest(
    async (params = {}) => {
      let { searchValue = '' } = params;
      let tokens = allTokenList?.tokens || [];

      //tokens  search value
      tokens = tokens.filter(
        (item: any) => item.chainId == webWalletData.chainId && (item.name.toLowerCase().includes(searchValue?.toLowerCase()) || item.symbol.toLowerCase().includes(searchValue?.toLowerCase()))
      );

      let customTokenList: any = await getImpoertTokenList(searchValue);

      let hiddenTokenList: ImportTokenInfo[] = await webWalletData.particleWallet?.getImpoertTokenList({
        userAddress: webWalletData.address?.toLowerCase(),
        chainNetwork: webWalletData.chainInfo.chainNetwork,
      });
      hiddenTokenList = hiddenTokenList.filter((item: any) => item.isHide);

      tokens = tokens.filter((item: any) => {
        return !(
          customTokenList.find((o: any) => o.address?.toLowerCase?.() == item.address?.toLowerCase?.()) ||
          hiddenTokenList.find((o: any) => o.tokenAddress?.toLowerCase?.() == item.address?.toLowerCase?.())
        );
      });

      // 合并 customTokenList and tokens
      tokens = [
        {
          title: t('added_token'),
          titleKey: 'titleKey1',
          nodeType: 'title',
        },
        ...customTokenList,
        {
          titleKey: 'titleKey2',
          title: t('token_not_added'),
          nodeType: 'title',
        },
        ...tokens,
      ];
      return tokens;
    },
    {
      debounceWait: 100,
      manual: true,
      onBefore: () => {
        setRequestDate(new Date().getTime());
      },
      onFinally: (data) => {
        setTimeout(() => {
          setLoading(false);
        }, Math.max(0, 100 - (new Date().getTime() - requestDate)));
      },
    }
  );

  const [importTokenAddressList = [], setImportTokenAddressList] = useState([] as any[]);

  const getImpoertTokenList = async (searchValue?: string) => {
    let customTokenList: ImportTokenInfo[] = await webWalletData.particleWallet?.getImpoertTokenList({
      userAddress: webWalletData.address?.toLowerCase(),
      chainNetwork: webWalletData.chainInfo.chainNetwork,
    });

    let allTokens = await webWalletData.particleWallet?.getAllToken();
    customTokenList = customTokenList
      .filter((item: ImportTokenInfo) => item.isSelected)
      .map((item: ImportTokenInfo) => {
        item = {
          ...item.metaData,
          isCustom: item.isCustom,
          isHide: item.isHide,
          isSelected: item.isSelected,
        };
        return item;
      });
    if (searchValue) {
      customTokenList = customTokenList.filter((item: any) => item.name.toLowerCase().includes(searchValue?.toLowerCase()) || item.symbol.toLowerCase().includes(searchValue?.toLowerCase()));
    }
    setImportTokenAddressList(customTokenList.map((item: any) => item.address));

    if (webWalletData.hideSuspiciousTokens && webWalletData.chainInfo.chainNetwork.toLowerCase() === 'mainnet') {
      const securityTokenAddressList = getSecurityTokenAddressList(webWalletData, allTokens, customTokenList);
      customTokenList = customTokenList.filter((item: any) => {
        return securityTokenAddressList.some((address: string) => address?.toLowerCase() === item.address?.toLowerCase());
      });
    }
    return customTokenList;
  };

  const selectedTokenHandle = async (tokenItem: any) => {
    let customTokenList: any = await getImpoertTokenList();
    let isSelected = false;
    let findData = customTokenList.find((item: any) => item.address?.toLowerCase?.() == tokenItem.address?.toLowerCase?.());
    if (findData) {
      isSelected = true;
    }

    const { address: userAddress } = webWalletData || {};
    const { chainName, chainId, chainNetwork } = webWalletData.chainInfo || {};

    if (tokenItem && tokenItem.logoURI) {
      tokenItem.image = tokenItem.logoURI;
    }
    const { name, symbol, image, address: tokenAddress, isCustom } = findData || tokenItem || {};

    const data: ImportTokenInfo = {
      chainName,
      chainId,
      userAddress,
      tokenAddress,
      chainNetwork,
      isSelected: !isSelected,
      isHide: isSelected,
      isCustom: isCustom ?? undefined,
      image,
      name,
      symbol,
      metaData: findData || tokenItem || {},
      updateTime: '',
    };

    webWalletData.particleWallet
      .addImportTokenData(data)
      .then((res) => {
        getImpoertTokenList().then();
      })
      .catch((error) => {
        getImpoertTokenList().then();
      });
  };

  useDebounceEffect(
    () => {
      if (webWalletData.isLogin && allTokenList && allTokenList.tokens && webWalletData.particleWallet) {
        requestTokenList({});
      }
    },
    [webWalletData.isLogin, allTokenList, webWalletData.particleWallet],
    {
      wait: 80,
    }
  );

  return (
    <div className="token-list-container">
      <div className="wrapper">
        <Header
          type={HeaderType.Search}
          search={{
            placeholder: t('search_for_token'),
            onSearch: (e: any) => {
              let searchValue = (e?.currentTarget?.value || '').trim();
              requestTokenList({ searchValue });
            },
          }}
        />
        <div className="page-content scroll-content-1">
          <div className="add-custom-token" onClick={() => router.push('/addCustomToken.html')}>
            <span>{t('add_custom_token')}</span>
            <span>
              <MIcon name="icon_arrow1" size={14} />
            </span>
            <MiniLink href="/addCustomToken.html" hidden={true} />
          </div>
          <div className="add-custom-token" onClick={() => router.push('/hiddenTokenList.html')}>
            <span>{t('hide_token')}</span>
            <span>
              <MIcon name="icon_arrow1" size={14} />
            </span>
            <MiniLink href="/hiddenTokenList.html" hidden={true} />
          </div>
          <div className="add-custom-token">
            <span>{t('hide_suspicious_tokens')}</span>
            <span>
              <Switch
                checked={webWalletData.hideSuspiciousTokens}
                onChange={(val) => {
                  webWalletData.hideSuspiciousTokens = val;
                  updateWebWalletData(webWalletData);
                }}
              />
            </span>
          </div>
          <ScrollContainer>
            {(loading || (loading && !tokenList.length)) && (
              <SkeletonList
                rows={3}
                style={{
                  margin: '0 20px',
                }}
              />
            )}

            <div className={`height-100 ${loading || (loading && !tokenList.length) ? 'hidden' : ''}`}>
              <TokenList dataList={tokenList}>
                {(record: any, index: number) => (
                  <div
                    className="operate"
                    data-type={importTokenAddressList.includes(record.address) ? 'remove' : 'add'}
                    data-is-hide={record.isHide ? 'true' : 'false'}
                    onClick={() => {
                      selectedTokenHandle(record);
                    }}
                  >
                    {importTokenAddressList.includes(record.address) ? <MIcon name="icon_61" size={20} /> : <MIcon name="icon_60" size={20} />}
                  </div>
                )}
              </TokenList>
            </div>
          </ScrollContainer>
        </div>
      </div>
    </div>
  );
};

export default Page;
