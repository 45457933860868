import WModel from '@/components/WModel';
import MIcon from '@/components/mIcon';
import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { useWebWallet } from '@/context/WebWalletContext';
import { formatPng, getGlobalChain } from '@particle-network/wallet-core';
import { Button, message } from 'antd';
import copy from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const svgToPng = (base64: string) => {
  return new Promise((resolve, reject) => {
    if (base64.indexOf('data:image/svg+xml;base64') == -1) {
      resolve(base64);
      return;
    } else {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        // @ts-ignore
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      };
      img.onerror = (err) => {
        reject(err);
      };
      img.src = base64;
    }
  });
};

interface Props {
  address: string;
}

const Content = (props: Props) => {
  const { address } = props;

  const { webWalletData } = useWebWallet();

  const { selectedGlobalChain } = useUniversalAccount();

  const { t } = useTranslation();

  const [logoSrc, setLogoSrc] = useState('');

  useEffect(() => {
    (async () => {
      if (selectedGlobalChain) {
        const data = getGlobalChain();
        setLogoSrc(data.nativeIcon);
      } else if (address && ((address === 'native' && webWalletData.chainInfo.icon) || address !== 'native')) {
        setTimeout(async () => {
          const icon = await webWalletData.particleWallet.getTokenIconByAddress(address);
          svgToPng(icon).then((res) => {
            setLogoSrc(res as string);
          });
        }, 200);
      }
    })();
  }, [selectedGlobalChain, webWalletData.chainInfo.chainId, webWalletData.particleWallet, webWalletData.chainInfo.icon, webWalletData.chainInfo.chainName, address]);

  const tip = useMemo(() => {
    if (selectedGlobalChain) {
      return t('support_assets_from_00b9f892');
    }
    const chainName = webWalletData?.chainInfo?.fullname?.replace?.('Mainnet', '').trim() || '';
    // @ts-ignore
    return t('only_support_o_related_assets').format(chainName);
  }, [webWalletData?.chainInfo, selectedGlobalChain]);

  return (
    <>
      <div className="content">
        {/* @ts-ignore */}
        <div className="tips-Nx9">{tip}</div>
        <div className="qr-code">
          {!!logoSrc && (
            <QRCode
              className="icon"
              value={webWalletData.copyAddress}
              renderAs="canvas"
              level="Q"
              size={260}
              key={webWalletData.copyAddress + '_' + webWalletData.chainInfo.icon + '_' + logoSrc + '_' + webWalletData.chainInfo.chainId}
              style={{
                padding: '8px',
                background: '#fff',
              }}
              imageSettings={{
                src: formatPng(logoSrc),
                width: 30,
                height: 30,
                excavate: true,
              }}
            />
          )}
        </div>
        <div
          className="address"
          onClick={() => {
            copy(webWalletData.copyAddress);
            message.success(t('copied_to'));
          }}
        >
          <span>{webWalletData.copyAddress}</span>
          <MIcon name="icon_14" />
        </div>
        <div className="copy-address">
          <CopyToClipboard
            text={webWalletData.copyAddress}
            onCopy={() => {
              message.success(t('copied_to'));
            }}
          >
            <Button type="primary">{t('copy_address')}</Button>
          </CopyToClipboard>
        </div>
        <div className="footer">
          <div className="logo" />
        </div>
      </div>
    </>
  );
};

const ReceiveAddress = forwardRef((props: any, ref) => {
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  const onClose = () => {
    setVisible(false);
  };

  const { webWalletData } = useWebWallet();

  const title = t('receive');

  useImperativeHandle(ref, () => ({
    setVisible,
  }));

  return (
    <>
      <WModel
        title={title}
        visible={visible}
        onClose={onClose}
        className="drawer-receive-container"
        modalProps={{
          width: 380,
          style: {},
        }}
        drawerProps={{
          closable: true,
          placement: 'bottom',
          height: 580,
        }}
      >
        <Content key={webWalletData.chainInfo.chainName} {...props} />
      </WModel>
    </>
  );
});

export default memo(ReceiveAddress);
