import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { memo } from 'react';

interface MiniLinkProps extends LinkProps {
  href: string;
  target?: string;
  children?: any;
  className?: string;
  hidden?: boolean;
  ['data-key']?: string;
}

export const MiniLink = memo(({ className, hidden, href, target, children, ...args }: MiniLinkProps) => {
  const router = useRouter();
  return (
    <div
      className={className + ' ' + (hidden ? 'mini-link-hidden' : '')}
      {...args}
      onClick={(e) => {
        if (target === '_blank') {
          window.open(href);
        } else {
          router.push(href);
        }

        e.stopPropagation();
      }}
    >
      <div className="mini-link-content">
        {children}
        <Link href={href} className={className}>
          <a target={target}></a>
        </Link>
      </div>
    </div>
  );
});
