import { useWebWallet } from '@/context/WebWalletContext';
import { useRequest } from 'ahooks';
import { useLayoutEffect } from 'react';

const useAllTokens = () => {
  const { webWalletData } = useWebWallet();

  const cacheKey = `getAllToken${webWalletData.chainName}_${webWalletData.chainId}`.toLowerCase();

  const { data, run } = useRequest(
    () => {
      return webWalletData.particleWallet?.getAllToken();
    },
    {
      cacheKey,
      manual: true,
    }
  );

  useLayoutEffect(() => {
    if (webWalletData.chainName && webWalletData.chainId) {
      run();
    }
  }, [webWalletData.chainName, webWalletData.chainId]);

  return {
    data,
  };
};

export default useAllTokens;
