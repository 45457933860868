import { isIframe } from '@/utils/index';
import { isServer } from '@particle-network/wallet-core';
import { useSetState, useSize } from 'ahooks';
import { cloneDeep } from 'lodash';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import MIcon from '../mIcon';

const doubleArrows = () => {
  const [webWalletData, setWebWalletData] = useSetState<any>({});

  const [codeExpand, setCodeExpand] = useState<boolean>(false);

  const displayDoubleArrow = useMemo(() => {
    if (!isServer()) {
      return window.self !== window.top && isIframe();
    } else {
      return false;
    }
  }, []);

  const pathname = useMemo(() => {
    return window.location.pathname;
  }, []);

  const bodySize = useSize(() => (isServer() ? null : document.querySelector('body')));

  useEffect(() => {
    if (window.screen.width > 600 && document.body.clientWidth < 600) {
      setCodeExpand(false);
    } else {
      setCodeExpand(true);
    }
    setWebWalletData({
      ...cloneDeep(window.__webWalletData || {}),
    });
  }, [bodySize]);

  useEffect(() => {
    setWebWalletData({
      // @ts-ignore
      ...cloneDeep(window.__webWalletData || {}),
    });
  }, []);

  return (
    <div className="double-arrows-container">
      {
        // 判断当前页面是否处于 iframe 中
        displayDoubleArrow && (
          <div
            data-pathname={pathname}
            className={webWalletData?.topMenuType !== 'close' ? 'double_arrow' : 'double_arrow close'}
            onClick={() => {
              if (webWalletData?.topMenuType !== 'close') {
                // @ts-ignore
                if (window.__appParams?.authSDKVersion || webWalletData.inAuthCoreIframe) {
                  window.parent.postMessage({ type: 'PARTICLE_WALLET_RESIZE_IFRAME' }, '*');
                } else {
                  const themeKey = `theme_${webWalletData.appId}`;
                  const storageTheme = window.storage.getItem(themeKey);
                  const [host, queryValue] = location.href.split('?');
                  const query = {
                    ...qs.parse(queryValue as string),
                  };
                  if (storageTheme) {
                    query.theme = storageTheme;
                  }
                  window.open(`${host}?${qs.stringify(query)}`);
                }
              } else {
                window.parent.postMessage('PARTICLE_WALLET_CLOSE_IFRAME', '*');
                window.parent.postMessage({ type: 'PARTICLE_WALLET_CLOSE_IFRAME' }, '*');
              }
            }}
          >
            <div className="close-icon">
              <MIcon name="icon_63" size={30} />
            </div>
            <div className="double-arrow-icon">{codeExpand ? <MIcon name="icon_79" size={30} /> : <MIcon name="icon_75" size={30} />}</div>
          </div>
        )
      }
    </div>
  );
};

export default doubleArrows;
