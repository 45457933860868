import WModel from '@/components/WModel';
import TransactionSuccessModel from '@/components/transactionSuccessModel';
import { useWebWallet } from '@/context/WebWalletContext';
import icon1 from '@/images/icon1.svg';
import { errorHandle, isCurrentVersionLower } from '@/utils/index';
import { TransactionType, waitForTransactionReceipt } from '@particle-network/wallet-core';
import { Button, Image } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './index.module.scss';

const UpgradeAccount = () => {
  const { t } = useTranslation();
  const [visibleUpgradeAccount, setVisibleUpgradeAccount] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const { webWalletData } = useWebWallet();
  const [loading, setLoading] = useState(false);
  const transactionSuccessModelRef = useRef<any>();

  const getAccount = () => {
    if (webWalletData?.particleWallet?.smartAccount?.getAccount) {
      webWalletData?.particleWallet?.smartAccount?.getAccount?.().then((res: any) => {
        const { implementationVersion } = res || {};
        console.log('>>>smartAccount', res);
        console.log('>>>implementationVersion', implementationVersion);
        if (!!implementationVersion) {
          setVisibleUpgradeAccount(isCurrentVersionLower(implementationVersion, '2.1.0'));
        }
      });
    }
  };

  const onUpgrade = async () => {
    setLoading(true);
    try {
      const to = webWalletData.address;
      const amount = '0';

      const hash = await webWalletData.particleWallet.sendTransaction('native', to, amount, TransactionType.UPGRADE_ACCOUNT);
      const publicClient = window.__webWalletData.getPublicClient(Number(window.__webWalletData.chainId));
      await waitForTransactionReceipt(publicClient, hash);
      setOpenModel(false);

      setTimeout(() => {
        webWalletData.particleWallet.getTransactionsList();
        getAccount();
      }, 300);

      transactionSuccessModelRef.current.setVisible(true, hash);
    } catch (error: any) {
      if (!error.message) {
        error.message = t('transaction_failed');
      }
      errorHandle(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (webWalletData.isBtcConnect && webWalletData?.particleWallet?.smartAccount?.getAccount) {
      getAccount();
    } else {
      setVisibleUpgradeAccount(false);
    }
  }, [webWalletData.isBtcConnect, webWalletData?.particleWallet?.smartAccount]);

  if (!visibleUpgradeAccount) {
    return null;
  }

  return (
    <div className={style.container}>
      <div className="upgrade-account-wrapper">
        <div className="upgrade-account-content">
          <div
            className="upgrade-account"
            onClick={() => {
              setOpenModel(true);
            }}
          >
            <span>{t('upgrade_your_btc_smart_account')}</span>
            <Image src={icon1} width={16} alt="icon1" preview={false} />
          </div>
        </div>

        <TransactionSuccessModel
          ref={transactionSuccessModelRef}
          onClose={() => {
            transactionSuccessModelRef.current.setVisible(false);
          }}
        />

        <WModel
          className="disconnect-content upgrade-account-model"
          visible={openModel}
          title={t('upgrade_smart_account')}
          modalProps={{
            width: '400px',
          }}
          containerSelector=".upgrade-account-wrapper"
          onClose={() => {
            setOpenModel(false);
          }}
        >
          <div className="modal-warn"></div>
          <div className="modal-des">{t('the_btc_smart_1ba679d8')}</div>
          <div className="modal-warn"></div>
          <div className="btns">
            <Button
              onClick={() => {
                setOpenModel(false);
              }}
            >
              {t('cancel')}
            </Button>
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                onUpgrade();
              }}
            >
              {t('upgrade')}
            </Button>
          </div>
          <div className="footer">
            <div className="logo" />
          </div>
        </WModel>
      </div>
    </div>
  );
};

export default UpgradeAccount;
