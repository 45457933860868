import ActivityIcon from '@/components/activityList/ActivityIcon';
import { MiniLink } from '@/components/miniLink';
import NoRecord from '@/components/noRecord';
import { useWebWallet } from '@/context/WebWalletContext';
import { formatTime } from '@/utils/index';
import { TransactionsType } from '@particle-network/wallet-core';
import { Skeleton } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { TokenDetailPageMode, TokenDetailTabKeys, amountView } from '..';
import styles from './index.module.scss';
interface IProps {
  tabActiveKey: TokenDetailTabKeys;
  pageMode: TokenDetailPageMode;
  transactionList: any[];
  transactionLoading: boolean;
}

const ActivityList = (props: IProps) => {
  const { tabActiveKey, pageMode, transactionList, transactionLoading } = props;
  const router = useRouter();
  const { webWalletData, t, updateWebWalletData } = useWebWallet();

  const tokenAddress = useMemo(() => {
    return (router.query.tokenAddress || 'native') as string;
  }, [router.query.tokenAddress]);

  const recordList = useMemo(() => {
    let _recordList = [...transactionList];
    if (tabActiveKey === TokenDetailTabKeys.Out) {
      _recordList = _recordList.filter((item) => item.action === 'out');
    } else if (tabActiveKey === TokenDetailTabKeys.In) {
      _recordList = _recordList.filter((item) => item.action === 'in');
    } else if (tabActiveKey === TokenDetailTabKeys.Failed) {
      _recordList = _recordList.filter((item) => item.status == 4 || item.action == 'failed');
    }
    _recordList = _recordList.map((item) => {
      if (item.status == 4 || item.action == 'failed') {
        item.iconType = '3';
      } else if (item.action == 'out') {
        item.iconType = '1';
      } else {
        item.iconType = '2';
      }
      // solana
      if (webWalletData.chainInfo.chainType === 'solana' && item.type == 'unknown' && item.action !== 'failed') {
        item.iconType = '5';
      }
      if (item.date) {
        item.date = formatTime(item.date);
      }
      return item;
    });

    for (let i = 0; i < _recordList.length; i++) {
      if (i == 0 && (_recordList[i].status == 0 || _recordList[i].status == 1)) {
        _recordList[i].title = 'pending';
      } else if (
        (i == 0 && _recordList[i].status !== 0 && _recordList[i].status !== 1) ||
        (i > 0 && (_recordList[i - 1].status == 0 || _recordList[i - 1].status == 1) && _recordList[i].status !== 0 && _recordList[i].status !== 1)
      ) {
        _recordList[i].title = 'completed';
        break;
      }
    }

    return _recordList;
  }, [transactionList, tabActiveKey]);

  useEffect(() => {
    if (webWalletData.isLogin && !transactionLoading) {
      updateWebWalletData({ useRequestCache: true });
    }
  }, [webWalletData.isLogin, transactionLoading]);

  return (
    <>
      {transactionLoading ? (
        <div className={styles['skeleton-record-list']}>
          {new Array(3).fill('').map((_, index) => {
            return <Skeleton avatar paragraph={{ rows: 1 }} active={false} key={index} />;
          })}
        </div>
      ) : (
        <div className={styles['record-list']}>
          {recordList && recordList.length ? (
            recordList.map((item, index: number) => {
              return (
                <div className="item-wrap" data-key={item.hash + '-' + index + '-' + item.status} key={item.hash + '-' + index + '-' + item.status}>
                  {pageMode === TokenDetailPageMode.Simple && (
                    <>
                      {
                        // item.title:pending||completed||null
                        item.title == 'pending' && (
                          <div className="item-title" data-index={index}>
                            {t('pending_v2')}
                          </div>
                        )
                      }
                      {
                        // item.title:pending||completed||null
                        item.title == 'completed' && (
                          <div className="item-title" data-index={index}>
                            {t('completed')}
                          </div>
                        )
                      }
                    </>
                  )}
                  <div
                    className={'record-item ' + ('type-' + item.iconType)}
                    data-key={item.hash + '-' + index + '-' + item.status}
                    key={item.hash + '-' + index + '-' + item.status}
                    onClick={() => router.push(`/transactionDetail.html?hash=${item.hash}&tokenAddress=` + tokenAddress)}
                  >
                    <div className="hidden">{JSON.stringify(item, null, 2)}</div>
                    <div className="left">
                      <div className={'icon ' + ('type-' + item.iconType)} data-type={item.iconType}>
                        <ActivityIcon
                          type={item.iconType == 1 ? TransactionsType.RSS3_NATIVE_SEND : item.iconType == 2 ? TransactionsType.RSS3_NATIVE_RECEIVE : TransactionsType.OTHER}
                          badge={item.badge}
                        />
                      </div>
                    </div>
                    <div className="right">
                      <div className="wrap">
                        <div className="row1">
                          <div className="address">{item.shortHash}</div>
                          <div className={'amount ' + ('type-' + item.iconType)}>{amountView(item, webWalletData)}</div>
                        </div>
                        <div className="row2">
                          <div className="left">{item.date}</div>
                          <div className="right">{item.status == 0 || item.status == 1 ? <div className="pending-btn">{t('pending')}</div> : ''}</div>
                        </div>
                      </div>
                    </div>
                    <MiniLink href={`/transactionDetail.html?hash=${item.hash}&tokenAddress=` + tokenAddress} hidden={true} />
                  </div>
                </div>
              );
            })
          ) : (
            <NoRecord description={t('as_you_start')} />
          )}
        </div>
      )}
    </>
  );
};

export default ActivityList;
