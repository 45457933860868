import { useWebWallet } from '@/context/WebWalletContext';
import { StartScanOption } from '@/types/index';
import { waitForElement } from '@/utils/index';
import { validSolanaAddress } from '@/utils/validate';
import { isValidAddress } from '@ethereumjs/util';
import { useConnectId } from '@particle-network/connect-react-ui';
import { EvmService } from '@particle-network/wallet-core';
import { PeerInfo, PeerMeta, SessionRequestEvent } from '@particle-network/walletconnect';
import { useDebounceEffect, useSetState, useUpdate } from 'ahooks';
import { Button, Form, Input, message, notification } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WModel from '../WModel';
import Header from '../header';
import MIcon from '../mIcon';

const qrCodeElementId = 'reader-scan-qrcode';

let Html5Qrcode: any;
let html5Qrcode: any;
let Html5QrcodeSupportedFormats: any;
let animationId: any;

const CameraPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const connectId = useConnectId();
  const [inputForm] = Form.useForm();

  const { webWalletData, updateWalletconnectData } = useWebWallet();

  const [openInputWCCode, setOpenInputWCCode] = useState<boolean>(false);

  const [modelVisible, setModelVisible] = useState<boolean>(false);

  const qrInputFileRef = useRef<HTMLInputElement>(null);

  const updateLayout = useUpdate();

  const [startScan, setStartScan] = useSetState<StartScanOption>({
    start: false, // 是否开始扫描
    hasCamera: false, // 是否有摄像头
    initcomplete: false, // 是否初始化完成
  });

  const enableWc = useMemo(() => {
    return (
      webWalletData.chainInfo.chainType !== 'solana' &&
      webWalletData.chainInfo?.chainName?.toLowerCase() !== 'tron' &&
      webWalletData.evmSupportWalletConnect &&
      (connectId === 'particle' || webWalletData.loginType === 'session')
    );
  }, [webWalletData.chainInfo, webWalletData.evmSupportWalletConnect, connectId, webWalletData.loginType]);

  const connectWC = (value: any) => {
    const { code } = value;
    if (code && webWalletData.wc2Manager?.isValidUri(code)) {
      connectWCWithUri(code);
      setOpenInputWCCode(false);
      closeQrCodeScan();
    } else {
      message.error(t('wc_connect_fail_tip'));
    }
  };

  const connectWCWithUri = async (uri: string) => {
    try {
      await connectWithWC(uri);
    } catch (error) {
      message.error(t('walletconnect_new_session_error'));
      console.log('err', error);
    }
  };

  const connectWithWC = async (uri: string) => {
    if (webWalletData.particleWallet.enableAccountAbstraction()) {
      const deployed = await (webWalletData.particleWallet as EvmService)?.smartAccount?.isDeployed();
      if (!deployed) {
        await new Promise((resolve, reject) => {
          webWalletData.events.once('deployWalletContractResult', (result) => {
            if (result) {
              resolve(true);
            } else {
              reject({ message: 'deploy contract canceled' });
            }
          });
          webWalletData.events.emit('deployWalletContractRequest');
        });
      }
    }

    webWalletData.wc2Manager?.events?.removeAllListeners(SessionRequestEvent.request);
    webWalletData.wc2Manager?.events?.removeAllListeners(SessionRequestEvent.connected);
    webWalletData.wc2Manager?.events?.once(SessionRequestEvent.request, (peerInfo: PeerInfo) => {
      console.log('WC2Manager SessionRequestEvent', peerInfo);
      updateWalletconnectData({
        displaySessionRequest: true,
        session: peerInfo,
      });
    });
    webWalletData.wc2Manager?.events?.once(SessionRequestEvent.connected, (peerMeta: PeerMeta) => {
      notification.success({
        placement: 'bottom',
        bottom: 20,
        message: t('connect_success'),
        description: peerMeta.url,
        btn: <></>,
        closeIcon: <></>,
      });
    });
    await webWalletData.wc2Manager?.connect(uri);
  };

  const openCameraModal = async () => {
    setModelVisible(true);

    const qrCodeElement: HTMLElement = await waitForElement(`#${qrCodeElementId}`);

    return qrCodeElement;
  };

  const closeQrCodeScan = async () => {
    try {
      if (html5Qrcode) {
        if (!html5Qrcode.canvasElement) {
          const canvas = document.createElement('canvas');
          html5Qrcode.canvasElement = canvas;
          html5Qrcode.element.appendChild(canvas);
        }
        await html5Qrcode.stop();
      }
    } catch (error) {}

    cancelAnimationFrame(animationId);

    setStartScan({
      hasCamera: false,
      start: false,
      initcomplete: false,
    });

    setModelVisible(false);

    message.destroy('loading-camera');

    setTimeout(() => {
      html5Qrcode = null;
    }, 500);
  };

  const scanFileHandler = async (e: any) => {
    if (e.target.files.length == 0) {
      return;
    }

    await closeQrCodeScan();

    const imageFile = e.target.files[0];

    html5Qrcode
      .scanFileV2(imageFile, false)
      .then((result: any) => {
        onScanSuccess(result.decodedText, result.result);
      })
      .catch((err: any) => {
        console.log('Error scanning file. Reason:', err);
        const errorMessage = err.message || '';
        if (errorMessage && !errorMessage.includes('Could not start video source')) {
          message.error(err.message);
          console.log('err', err);
        }
      });
  };

  const onScanSuccess = async (decodedText: string, decodedResult: any) => {
    console.log(`Code matched = ${decodedText}`, decodedResult);
    closeQrCodeScan();
    if (webWalletData.particleWallet.chainType === 'evm') {
      if (webWalletData.evmSupportWalletConnect && webWalletData.wc2Manager?.isValidUri(decodedText)) {
        await connectWCWithUri(decodedText);
      } else {
        if (decodedText.match(/^ethereum/)) {
          decodedText = decodedText.replace(/^ethereum:/, '').split('@')[0];
        }
        if (isValidAddress(decodedText)) {
          // router to send evm token
          router.push(`/send.html?tokenAddress=native&to=${decodedText}`);
        } else {
          message.error(t('unrecognized_qr_code'));
        }
      }
    } else {
      // solana
      if (validSolanaAddress(decodedText)) {
        // router to send solana token
        router.push(`/send.html?tokenAddress=native&to=${decodedText}`);
      } else {
        message.error(t('unrecognized_qr_code'));
      }
    }
  };

  const onScanFailure = (error: any) => {
    // handle scan failure, usually better to ignore and keep scanning.
    //ignore
    // console.log('onScanFailure', error);
  };

  const previewHandle = () => {
    try {
      if (html5Qrcode && html5Qrcode?.renderedCamera?.getSurface?.() && html5Qrcode?.canvasElement?.toDataURL) {
        const videoElement = html5Qrcode?.renderedCamera?.getSurface?.();
        let previewCanvas = document.querySelector(`#${qrCodeElementId} .preview-canvas`) as HTMLCanvasElement;

        if (!previewCanvas) {
          previewCanvas = document.createElement('canvas');
          previewCanvas.className = 'preview-canvas';
          document.querySelector(`#${qrCodeElementId}`)?.appendChild(previewCanvas);
          previewCanvas.setAttribute('width', videoElement.videoWidth.toString());
          previewCanvas.setAttribute('height', videoElement.videoHeight.toString());
        }
        const context = previewCanvas.getContext('2d') as CanvasRenderingContext2D;
        context.drawImage(videoElement, 0, 0, previewCanvas.width, previewCanvas.height);
      }
    } catch (error) {
      console.log('previewHandle error', error);
    }
    animationId = requestAnimationFrame(previewHandle);
  };

  /**
   * Initialize QR Code Scanner
   */
  const initializeQrCodeScanner = async () => {
    try {
      setStartScan({
        start: true,
        hasCamera: true,
        initcomplete: false,
      });

      message.loading({ key: 'loading-camera', content: t('launching_camera') + '...' });

      const qrCodeElement: HTMLElement = await openCameraModal();

      const module: any = await import('html5-qrcode');
      Html5QrcodeSupportedFormats = module.Html5QrcodeSupportedFormats;
      Html5Qrcode = module.Html5Qrcode;

      if (!Html5Qrcode) {
        throw new Error('Html5Qrcode is not defined, please try again later');
      }

      html5Qrcode = new Html5Qrcode(qrCodeElementId, { formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE], verbose: false });

      const devices = await Html5Qrcode.getCameras();
      console.log('Cameras', devices);

      if (qrCodeElement) {
        const config = { fps: 10, aspectRatio: 1, disableFlip: false };

        if (html5Qrcode?.isScanning) {
          try {
            await html5Qrcode.stop();
          } catch (error) {}
        }

        console.log('config', config);

        html5Qrcode?.start({ facingMode: 'environment' }, config, onScanSuccess, onScanFailure).then(() => {
          if (animationId) {
            cancelAnimationFrame(animationId);
          }
          previewHandle();
          waitForElement('#reader-scan-qrcode #qr-canvas').then(() => {
            message.destroy('loading-camera');
            setStartScan({
              initcomplete: true,
            });
          });
        });
      } else {
        throw new Error(`element ${qrCodeElementId} not found`);
      }
    } catch (error) {
      openCameraModal();
      message.destroy('loading-camera');

      console.log('Camera error:', error);

      if (error === 'NotFoundError : Requested device not found' || error === 'OverconstrainedError : Invalid constraint') {
        setStartScan({
          hasCamera: false,
          start: true,
          initcomplete: true,
        });
      } else if (error === 'NotAllowedError : Permission denied') {
        message.error(t('permission_denied_please_7a345f05'));
        setStartScan({
          hasCamera: false,
          start: false,
          initcomplete: true,
        });
        console.log('err', error);
      } else {
        setStartScan({
          hasCamera: false,
          start: false,
          initcomplete: true,
        });
      }
    }
  };

  useDebounceEffect(
    () => {
      const fileinput = document.getElementById('qr-input-file');
      if (modelVisible && webWalletData.chainInfo.chainType !== 'solana' && qrInputFileRef.current && fileinput && fileinput.getAttribute('bindchange') !== 'true') {
        fileinput?.addEventListener('change', scanFileHandler);
        fileinput?.setAttribute('bindchange', 'true');
        fileinput?.addEventListener('click', (e) => {
          (fileinput as any).value = '';
        });
      }
    },
    [modelVisible, webWalletData.chainInfo.chainType, html5Qrcode, qrInputFileRef.current],
    {
      wait: 500,
    }
  );

  useEffect(() => {
    if (startScan.start) {
      setTimeout(() => {
        updateLayout();
      });
    }
  }, [startScan.start]);

  useEffect(() => {
    if (openInputWCCode) {
      inputForm.resetFields();
    }
  }, [inputForm, openInputWCCode]);

  useEffect(() => {
    setTimeout(() => {
      if (!Html5Qrcode) {
        import('html5-qrcode')
          .then((module) => {
            Html5QrcodeSupportedFormats = module.Html5QrcodeSupportedFormats;
            Html5Qrcode = module.Html5Qrcode;
          })
          .catch((err) => {});
      }
    }, 3000);
    return () => {
      const fileinput = document.getElementById('qr-input-file');
      if (fileinput) {
        fileinput?.removeEventListener('change', scanFileHandler);
        fileinput?.removeAttribute('bindchange');
      }
    };
  }, []);

  return (
    <div className="camera-container">
      {webWalletData.displayScan && (
        <div onClick={initializeQrCodeScanner} className="pn-wc-scan-btn">
          <MIcon name="icon_25" size={30} />
        </div>
      )}
      <WModel
        className={`camera-modal-container model-visible-${modelVisible ? 1 : 0}`}
        visible={modelVisible}
        locked="modal"
        modalProps={
          webWalletData.viewType == 'desktop'
            ? {
                motion: false,
                width: 420,
                centered: true,
                focusTriggerAfterClose: false,
                closable: false,
                destroyOnClose: true,
                footer: null,
              }
            : {
                motion: false,
                width: '100%',
                centered: true,
                focusTriggerAfterClose: false,
                closable: false,
                destroyOnClose: true,
                footer: null,
                style: {
                  width: '100%',
                  height: '100%',
                  padding: 0,
                  margin: 0,
                  maxWidth: '100%',
                },
              }
        }
        onOpen={() => {
          setModelVisible(true);
        }}
        onClose={() => {
          setModelVisible(false);
        }}
      >
        <div className="camera-page-container">
          <div className="camera-wrap">
            <div
              id="reader-scan-qrcode"
              className={`qrcode-container`}
              data-initcomplete={startScan.initcomplete ? 'true' : 'false'}
              data-start={startScan.start ? 'true' : 'false'}
              data-has-camera={startScan.start ? (startScan.hasCamera ? true : false) : ''}
            />
            {(startScan.start || startScan.initcomplete) && (
              <>
                {!startScan.hasCamera && (
                  <div className="camera-not-found">
                    <div className="text-hint">{t('camera_not')}</div>
                  </div>
                )}

                <div className="header-box">
                  <Header
                    wrapperClassName="qr-code-header"
                    title={
                      <span
                        onClick={() => {
                          if (webWalletData.debug) {
                            const canvas = document.querySelector('canvas');
                            const base64 = canvas?.toDataURL('image/png');
                            if (base64) {
                              const newWindow = window.open();
                              newWindow?.document.write(`<img src="${base64}" />`);
                            }
                          }
                        }}
                      >
                        {t('scan_qr')}
                      </span>
                    }
                    onBack={() => {
                      closeQrCodeScan();
                    }}
                    rightContext={
                      enableWc && (
                        <Button
                          type="primary"
                          className="connected-btn"
                          onClick={() => {
                            closeQrCodeScan();
                            router.push('/connectedSitesList.html');
                          }}
                        >
                          {t('connected')}
                        </Button>
                      )
                    }
                  />
                </div>
                <div className="camera-scan-border">
                  {startScan.start && startScan.hasCamera && <div className="scan-anime"></div>}
                  <MIcon name="icon_78" size={262} />
                </div>
                <div className="camera-content">
                  <div className="camera-text2">{enableWc ? t('initiate_a_transfer') : t('initiate_a')}</div>

                  {enableWc && (
                    <div className="camera-btn">
                      <input type="file" style={{ display: 'none' }} id="qr-input-file" accept="image/*" ref={qrInputFileRef} />
                      <Button
                        type="primary"
                        className="select-btn"
                        onClick={() => {
                          (document.querySelector('#qr-input-file') as any)?.click();
                        }}
                      >
                        {t('select_from')}
                      </Button>
                      <Button
                        type="primary"
                        className="connect-btn"
                        onClick={() => {
                          setOpenInputWCCode(true);
                        }}
                      >
                        {t('paste_wc_code')}
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </WModel>

      <WModel
        className="input-wc-modal"
        visible={openInputWCCode}
        onClose={() => setOpenInputWCCode(false)}
        modalProps={{
          width: 360,
        }}
      >
        <div className="modal-input-content">
          <div className="input-wc-title">{t('paste_wc_code')}</div>
          <Form className="input-wc-form" onFinish={connectWC} form={inputForm} autoComplete="off">
            <Form.Item name="code">
              <Input className="input-wc w-input" placeholder={t('paste_wc_code')} />
            </Form.Item>
            <Form.Item>
              <Button className="input-wc-connect" type="primary" htmlType="submit">
                {t('connect')}
              </Button>
            </Form.Item>
          </Form>
          <div className="footer">
            <div className="logo"></div>
          </div>
        </div>
      </WModel>
    </div>
  );
};

export default CameraPage;
