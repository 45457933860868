import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { useWebWallet } from '@/context/WebWalletContext';
import { useConnectId, useSwitchChains } from '@particle-network/connect-react-ui';
import { iframeWallet } from '@particle-network/iframe-wallet';
import { ChainInfo, SWAP_CHAIN_LIST, getChainList, getGlobalChain } from '@particle-network/wallet-core';
import { useDebounceEffect } from 'ahooks';
import { cloneDeep } from 'lodash';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { isServer } from '../utils';

const useChainList = () => {
  const router = useRouter();
  const { webWalletData, updateWebWalletData } = useWebWallet();
  const [showSwtichButton, setShowSwtichButton] = useState(true);
  // isSwtichChain：当前登录状态是否可以切换链
  const { isSwtichChain, renderChains } = useSwitchChains();
  const [displaySelectChainList, setDisplaySelectChainList] = useState<boolean>(true);
  const [chainList, setChainList] = useState<any[]>([]);
  const connectId = useConnectId();
  const { displayAllNetworks, selectedGlobalChain } = useUniversalAccount();

  useDebounceEffect(
    () => {
      setTimeout(() => {
        let list = getChainList(true, webWalletData.chainId.toString(), webWalletData.chainName);
        if (router.pathname.includes('/swap')) {
          list = getChainList(false, webWalletData.chainId.toString(), webWalletData.chainName);
          list = list.filter((item) => {
            return !!SWAP_CHAIN_LIST.find((o: any) => o.chainId == item.chainId && o.chainName.toLowerCase() == item.chainName.toLowerCase());
          });
        } else if (router.pathname == '/') {
        }

        if (webWalletData.accountAbstraction && webWalletData.particleWallet?.enableAccountAbstraction?.()) {
          const aaChains = webWalletData.particleWallet.accountAbstractionChains();
          list = list.filter((chainInfo) => aaChains.includes(Number(chainInfo.chainId)));
        }

        // filter webWalletData.supportChains
        if (webWalletData.supportChains && webWalletData.supportChains.length) {
          try {
            list = list.filter((item) => {
              return !!webWalletData?.supportChains?.find?.((o: any) => o.id == item.chainId && o.name.toLowerCase() == item.chainName.toLowerCase());
            });
          } catch (error) {
            console.log(error);
          }
        }

        if (webWalletData.loginType !== 'session') {
          list = list.filter((item) => {
            return !!renderChains.find((o) => o.id == item.chainId && o.name.toLowerCase() == item.chainName.toLowerCase());
          });

          if (connectId !== 'particle') {
            list = list.filter((item) => {
              return item.chainName.toLowerCase() !== 'tron';
            });
          }
        }

        // 1、loginType == 'connect' && !isSwtichChain
        // 2、list.length == 1
        // 3、swap 页面 && 主网链只有一个
        if (
          (webWalletData.loginType == 'connect' && !isSwtichChain) ||
          list.length == 1 ||
          (router.pathname.includes('/swap') &&
            list.filter((item) => {
              return item?.chainNetwork?.toLowerCase?.() == 'mainnet';
            }).length == 1)
        ) {
          setDisplaySelectChainList(false);
        } else {
          setDisplaySelectChainList(true);
        }

        // 设置是否显示切换按钮
        // 页面是不是swap页面，并且 至少有一个主链或者一个测试链
        if (
          !router.pathname.includes('/swap') &&
          list.filter((item) => {
            return item?.chainNetwork?.toLowerCase?.() !== 'mainnet';
          }).length > 0 &&
          list.filter((item) => {
            return item?.chainNetwork?.toLowerCase?.() == 'mainnet';
          }).length > 0
        ) {
          setShowSwtichButton(true);
        } else {
          setShowSwtichButton(false);
        }

        // 只有测试链时，默认展开
        if (
          !router.pathname.includes('/swap') &&
          list.length &&
          list.filter((item) => {
            return item?.chainNetwork?.toLowerCase?.() == 'mainnet';
          }).length == 0
        ) {
          updateWebWalletData({ showTestNetWork: true });
        }

        let currentChainList: any[] = cloneDeep(list);
        if (!webWalletData.showTestNetWork) {
          // 只显示主链
          currentChainList = currentChainList.filter((item) => {
            return item?.chainNetwork?.toLowerCase?.() == 'mainnet';
          });
        }

        if (displayAllNetworks) {
          currentChainList.unshift(getGlobalChain());
          if (selectedGlobalChain) {
            currentChainList.forEach((item) => {
              item.selected = false;
            });
            currentChainList[0].selected = true;
          }
        }

        if (webWalletData.inAuthCoreIframe) {
          filterAuthCoreChain(currentChainList);
        } else {
          setChainList(currentChainList);
        }
      });
    },
    [
      webWalletData.showTestNetWork,
      webWalletData.supportChains,
      webWalletData.chainId,
      webWalletData.chainName,
      webWalletData.address,
      isSwtichChain,
      renderChains,
      connectId,
      webWalletData.isLogin,
      webWalletData.address,
      webWalletData.theme,
      webWalletData.loginType,
      router.pathname,
      webWalletData.inAuthCoreIframe,
      selectedGlobalChain,
    ],
    {
      wait: 100,
    }
  );

  const filterAuthCoreChain = async (list: ChainInfo[]) => {
    try {
      await iframeWallet.ethereum?.request({ method: 'eth_chainId' });
    } catch (error) {
      list = list.filter((item) => {
        return item?.chainType !== 'evm';
      });
    }
    try {
      await iframeWallet.solana?.getChainId();
    } catch (error) {
      list = list.filter((item) => {
        return item?.chainType !== 'solana';
      });
    }
    setChainList(list);
  };

  if (!isServer()) {
    // @ts-ignore
    window.supportedChains = chainList;
  }

  return {
    displaySelectChainList,
    chainList,
    showSwtichButton,
  };
};

export default useChainList;
