import { useRouterHistory } from '@/context/RouterHistoryContext';
import { isServer } from '@/utils/env-util';
import { Input } from 'antd';
import { useRouter } from 'next/router';
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import DoubleArrows from '../doubleArrows';
import MIcon from '../mIcon';

export enum HeaderType {
  Search = 'Search',
}
const Header = forwardRef((props: any, ref) => {
  const router = useRouter();
  const isbuyPage = props.name == 'buy';
  const displayDoubleArrows = props.displayDoubleArrows || false;

  const onSearch = () => {};

  const { history, back } = useRouterHistory();

  useImperativeHandle(ref, () => ({
    onSearch,
  }));

  const [displayBackButton, setDisplayBackButton] = useState(false);

  useEffect(() => {
    let result = false;

    // 服务端 || iframe || (ramp 首页&&showBackButton!=true)  隐藏返回按钮
    if (
      isServer() ||
      props.hiddenBackButton ||
      (!!window.opener && (isbuyPage || location.pathname == '/buy.html')) ||
      (location.host.includes('ramp') && (location.pathname == '/' || location.pathname === '/index.html') && !router.asPath.includes('showBackButton=true')) ||
      router.asPath.includes('hideBackButton=true')
    ) {
      result = false;
    } else {
      result = true;
    }

    setDisplayBackButton(result);
  }, [router?.pathname]);

  return (
    <div className={`header-container ${HeaderType.Search == props.type ? 'header-search-container' : ''} ${props.wrapperClassName || ''}`}>
      <div className="wrapper">
        <div className="left">
          {displayBackButton && (
            <div
              className="back-btn-wrap"
              onClick={
                props.onBack ??
                (() => {
                  if (history?.length === 1) {
                    back('/');
                  } else {
                    router.back();
                  }
                })
              }
            >
              <div className="back-btn">
                <MIcon name="icon_71" size={30} />
              </div>
            </div>
          )}
          {displayDoubleArrows && <DoubleArrows />}
        </div>
        <div className="middle">
          <div className="wrap">
            {props.type == HeaderType.Search ? (
              <>
                <Input
                  bordered={false}
                  allowClear={true}
                  size="large"
                  className="header-search-input-wrapper"
                  placeholder={props.search.placeholder}
                  onChange={props?.search?.onSearch}
                  prefix={<MIcon name="icon_59" size={20} />}
                  onFocus={() => {
                    document.querySelector('.header-search-input-wrapper')?.setAttribute('data-focus', 'true');
                  }}
                  onBlur={() => {
                    document.querySelector('.header-search-input-wrapper')?.setAttribute('data-focus', 'false');
                  }}
                />
              </>
            ) : (
              props?.title || ''
            )}
          </div>
        </div>
        <div className="right">{props?.rightContext}</div>
      </div>
    </div>
  );
});

export default memo(Header);
