import { Skeleton } from 'antd';

import styles from './index.module.scss';
interface IProps {
  rows?: number;
  style?: React.CSSProperties;
}
const SkeletonList = (props: IProps) => {
  const { rows = 3, style } = props;
  return (
    <div className={styles.container} style={style}>
      {new Array(rows).fill('').map((_, index) => {
        return <Skeleton avatar paragraph={{ rows: 3 }} active={false} key={index} />;
      })}
    </div>
  );
};

export default SkeletonList;
