import { SwtichNetworkStatus, useWebWallet } from '@/context/WebWalletContext';
import useChainList from '@/hooks/useChainList';
import { AccountAbstractionType, defaultTokenIcon, getChainList } from '@particle-network/wallet-core';
import { Image, Switch, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../header';

export const getAAIcon = (aaType: string, theme: string) => {
  aaType = (aaType ?? 'biconomy')?.toLowerCase();
  const aaIcons: Record<string, any> = {
    biconomyfff: require('@/images/smartAccount/biconomy-fff-icon.png'),
    biconomy000: require('@/images/smartAccount/biconomy-000-icon.png'),
    cyberconnectfff: require('@/images/smartAccount/cyberconnect-fff-icon.png'),
    cyberconnect000: require('@/images/smartAccount/cyberconnect-000-icon.png'),
    simplefff: require('@/images/smartAccount/simple-fff-icon.png'),
    simple000: require('@/images/smartAccount/simple-000-icon.png'),
    lightfff: require('@/images/smartAccount/light-icon.png'),
    light000: require('@/images/smartAccount/light-icon.png'),
    btcfff: require('@/images/smartAccount/btc-icon.png'),
    btc000: require('@/images/smartAccount/btc-icon.png'),
    universalfff: require('@/images/smartAccount/universal-fff-icon.png'),
    universal000: require('@/images/smartAccount/universal-000-icon.png'),
    xteriofff: require('@/images/smartAccount/xterio-icon.png'),
    xterio000: require('@/images/smartAccount/xterio-icon.png'),
    coinbase000: require('@/images/smartAccount/coinbase-icon.png'),
    coinbasefff: require('@/images/smartAccount/coinbase-icon.png'),
  };
  const key = `${aaType}${theme === 'light' ? 'fff' : '000'}`;
  return aaIcons[key] ?? aaIcons['biconomyfff'];
};

const Experimental = () => {
  const { webWalletData, updateWebWalletData } = useWebWallet();
  const { t } = useTranslation();
  const { chainList } = useChainList();

  // @ts-ignore
  const [typeName, setTypeName] = useState<string>(webWalletData.accountAbstraction?.name || '');

  const [aaVersion, setAAVersion] = useState<string>('1.0.0');

  let list = [
    {
      typeName: AccountAbstractionType.BICONOMY,
      title: t('biconomy_account') + ' 1.0.0',
      icon: getAAIcon(AccountAbstractionType.BICONOMY, webWalletData.theme),
      version: '1.0.0',
    },
    {
      typeName: AccountAbstractionType.BICONOMY,
      title: t('biconomy_account') + ' 2.0.0',
      icon: getAAIcon(AccountAbstractionType.BICONOMY, webWalletData.theme),
      version: '2.0.0',
    },

    {
      typeName: AccountAbstractionType.CYBERCONNECT,
      title: t('cyber_account'),
      icon: getAAIcon(AccountAbstractionType.CYBERCONNECT, webWalletData.theme),
      version: '1.0.0',
    },
    {
      typeName: AccountAbstractionType.LIGHT,
      title: t('alchemy_account'),
      icon: getAAIcon(AccountAbstractionType.LIGHT, webWalletData.theme),
      version: '1.0.2',
    },
    {
      typeName: AccountAbstractionType.SIMPLE,
      title: t('simple_account') + ' 1.0.0',
      icon: getAAIcon(AccountAbstractionType.SIMPLE, webWalletData.theme),
      version: '1.0.0',
    },
    {
      typeName: AccountAbstractionType.SIMPLE,
      title: t('simple_account') + ' 2.0.0',
      icon: getAAIcon(AccountAbstractionType.SIMPLE, webWalletData.theme),
      version: '2.0.0',
    },
    {
      typeName: AccountAbstractionType.BTC,
      title: t('btc_account'),
      icon: getAAIcon(AccountAbstractionType.BTC, webWalletData.theme),
      version: '1.0.0',
    },
    // {
    //   typeName: AccountAbstractionType.UNIVERSAL,
    //   title: 'Universal Account',
    //   icon: getAAIcon(AccountAbstractionType.UNIVERSAL, webWalletData.theme),
    //   version: '1.0.0',
    // },
    {
      typeName: AccountAbstractionType.XTERIO,
      title: 'Xterio Account',
      icon: getAAIcon(AccountAbstractionType.XTERIO, webWalletData.theme),
      version: '1.0.0',
    },
  ];

  /**
   * 根据 webWalletData 中的 erc4337.name 进行过滤列表。
   * 如果 erc4337.name 为 AccountAbstractionType.BTC，则只保留 typeName 为 AccountAbstractionType.BTC 的项。
   * 否则，移除 typeName 为 AccountAbstractionType.BTC 的项。
   */
  // @ts-ignore
  if (webWalletData.erc4337?.name === AccountAbstractionType.BTC) {
    list = list.filter((item) => item.typeName === AccountAbstractionType.BTC);
    // @ts-ignore
  } else {
    list = list.filter((item) => item.typeName !== AccountAbstractionType.BTC);
  }

  //TODO: remove UNIVERSAL
  if (process.env.NEXT_PUBLIC_APP_ENV !== 'development') {
    list = list.filter((item) => item.typeName !== AccountAbstractionType.UNIVERSAL);
  }

  const onAAChange = async (title: string, aaName: string, version: string) => {
    if (typeName === aaName && aaVersion === version) {
      return;
    }
    // 清除地址
    // updateWebWalletData({
    //   address: '',
    // });

    if (webWalletData.particleWallet?.smartAccount) {
      webWalletData.particleWallet?.smartAccount.setSmartAccountContract({
        name: aaName,
        version: version,
      });
    }

    const accountAbstractionChainIds = webWalletData.particleWallet.accountAbstractionChains(aaName, version);
    setTypeName(aaName);
    const config = { name: aaName, version: version || '1.0.0' };

    window.storage.setItem('accountAbstractionSwitchConfig', JSON.stringify(config));
    updateWebWalletData({ accountAbstraction: config, balance: -1, useRequestCache: false });

    try {
      // removeAll connected sites
      await webWalletData?.wc2Manager?.removeAll();
    } catch (error) {
      console.log('wc2Manager removeAll', error);
    }

    setTimeout(async () => {
      // 判断当前链是否支持aa,如果不支持先切换到支持的链
      if (webWalletData.chainInfo && !accountAbstractionChainIds.includes(Number(webWalletData.chainId))) {
        const chainInfo = getChainList(true, '', '')?.find?.((item) => accountAbstractionChainIds.includes(Number(item.chainId)));
        if (chainInfo) {
          updateWebWalletData({
            chainId: chainInfo.chainId,
            chainName: chainInfo.chainName,
            swtichNetwork: SwtichNetworkStatus.loading,
          });

          await new Promise((resolve, reject) => {
            const checkNetworkStatus = () => {
              if (window.__webWalletData.swtichNetwork === SwtichNetworkStatus.success) {
                resolve(true);
              } else if (window.__webWalletData.swtichNetwork === SwtichNetworkStatus.fail) {
                reject(false);
              } else {
                setTimeout(checkNetworkStatus, 10);
              }
            };

            checkNetworkStatus();
          });
        }
      }

      setTimeout(() => {
        message.success((t('switch_to_n_wallet_on_n') as any).format(title, window.__webWalletData.chainInfo.fullname));
      });

      updateWebWalletData({ accountAbstraction: config, balance: -1, useRequestCache: false });
    }, 100);
  };

  const disabled =
    // @ts-ignore
    webWalletData.erc4337 === 'true' ||
    // @ts-ignore
    webWalletData.erc4337 === 'false' ||
    // @ts-ignore
    webWalletData?.erc4337?.name === AccountAbstractionType.BTC ||
    // @ts-ignore
    webWalletData?.erc4337?.name === AccountAbstractionType.UNIVERSAL ||
    !!(chainList && !chainList?.find?.((item) => webWalletData.particleWallet.accountAbstractionChains().includes(Number(item.chainId))));

  const getSupportAAName = () => {
    let aaName = AccountAbstractionType.BICONOMY;
    let aaVersion = '1.0.0';
    for (let i = 0; i < list.length; i++) {
      const { typeName, version } = list[i];

      const accountAbstractionChainIds = webWalletData.particleWallet.accountAbstractionChains(typeName, version);
      if (accountAbstractionChainIds.includes(Number(webWalletData.chainId))) {
        aaName = typeName;
        aaVersion = version;
        break;
      }
    }
    return { aaName, aaVersion };
  };

  const onAAEnableChanged = async (enable: boolean) => {
    if (enable) {
      const { aaName, aaVersion } = getSupportAAName();
      setTypeName(aaName);
      setAAVersion(aaVersion);
      let { title, version } = list.find((item) => item.typeName === aaName && item.version === aaVersion) || {};

      onAAChange(title || t('biconomy_account'), aaName, version || '1.0.0');
    } else {
      window.storage.setItem('accountAbstractionSwitchConfig', 'false');
      updateWebWalletData({ accountAbstraction: false, balance: -1, useRequestCache: false });
      setTypeName('');
      try {
        // removeAll connected sites
        await webWalletData?.wc2Manager?.removeAll();
      } catch (error) {
        console.log('wc2Manager removeAll', error);
      }
    }
  };

  useEffect(() => {
    if (webWalletData.accountAbstraction) {
      // @ts-ignore
      setAAVersion(webWalletData.accountAbstraction?.version || '1.0.0');
    }
  }, [webWalletData.accountAbstraction]);

  return (
    <div className="experimental-container">
      <div className="wrapper">
        <Header title={t('smart_account')} />
        <div className="list">
          <div className="item float use-smart-account">
            <div className="item-title">{t('use_smart_account')}</div>
            <Switch checked={Boolean(webWalletData.accountAbstraction)} onChange={onAAEnableChanged} disabled={disabled} />
          </div>
          {webWalletData.accountAbstraction &&
            list.map((item, index) => {
              return (
                <div className="item" key={index} onClick={() => onAAChange(item.title, item.typeName, item.version)}>
                  <div className="left">
                    <div className="icon">
                      <Image src={item.icon} fallback={defaultTokenIcon} preview={false} width={22} height={22} />
                    </div>
                    <div className="item-title">{item.title}</div>
                  </div>
                  <div className="right">
                    <div className={`icon ${typeName === item.typeName && aaVersion === item.version ? 'selected' : ''}`}></div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Experimental;
