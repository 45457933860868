import { MiniLink } from '@/components/miniLink';
import NoRecord from '@/components/noRecord';
import { TokenList } from '@/components/tokenList/index';
import { useWebWallet } from '@/context/WebWalletContext';
import { TokenInfo, formatAmount } from '@particle-network/wallet-core';
import { Skeleton } from 'antd';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

interface IProps {
  loading: boolean;
  tokenList: TokenInfo[];
}
const TonkenList = (props: IProps) => {
  const { loading, tokenList } = props;
  const router = useRouter();

  const { t } = useTranslation();

  const { webWalletData } = useWebWallet();

  // 法币金额
  const fiatAmount = (record: any) => {
    let amount = '';
    if (Number((record?.price.toString() || '0').replace(/\,/g, '')) > 0.01) {
      amount = '≈' + record.price;
    } else {
      amount = '≈0';
    }
    return `${amount} ${webWalletData.currency}`;
  };

  return (
    <>
      {loading ? (
        <div className="skeleton-token-list">
          {new Array(3).fill('').map((_, index) => {
            return (
              <Skeleton avatar paragraph={{ rows: 4 }} active={false} key={index} />
              // <div className="skeleton-item">
              //   <div className="left">
              //     <Skeleton avatar paragraph={{ rows: 1 }} active={true} key={index} />
              //   </div>
              //   <div className="right">
              //     <Skeleton.Input active={true} />
              //   </div>
              // </div>
            );
          })}
        </div>
      ) : tokenList && tokenList.length ? (
        // 渲染列表
        <div className="">
          {/* <pre className="hidden">{JSON.stringify(tokenList, null, 2)}</pre> */}
          <TokenList
            dataList={tokenList}
            itemClick={(tokenInfo: TokenInfo) => {
              router.push(`/tokenDetail.html?tokenAddress=${tokenInfo.address}&tokenType=${tokenInfo.tokenType || ''}`);
            }}
          >
            {(record: TokenInfo) => {
              return (
                <>
                  <div className="p1">{formatAmount(record.amount || 0, 9)}</div>
                  {parseFloat(record.doubleUnitPrice) > 0 ? (
                    <div className="p2" data-u-price={record.doubleUnitPrice}>
                      {fiatAmount(record)}
                    </div>
                  ) : (
                    <div className="p3"></div>
                  )}
                  <MiniLink href={`/tokenDetail.html?tokenAddress=${record.address}`} hidden={true} />
                  {/* <MIcon name="icon_12" className="right-arrow" size={22} /> */}
                </>
              );
            }}
          </TokenList>
        </div>
      ) : (
        // 暂无数据
        <NoRecord />
      )}
    </>
  );
};
export default TonkenList;
