import { useWebWallet } from '@/context/WebWalletContext';
import { ITokensAndNFTsResponse } from '@particle-network/wallet-core';
import { useDocumentVisibility, useRequest, useSetState } from 'ahooks';
import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { getSecurityMethod } from '../utils';
import useImpoertTokenList from './useImpoertTokenList';

interface IProps {
  pollingInterval?: number;
  pollingWhenHidden?: boolean;
  chainId?: number;
  chainName?: string;
  isRequiredChainInfo?: boolean;
}

const useTokensAndNFTs = (props: IProps) => {
  const { pollingInterval = 10000, pollingWhenHidden = false } = props;

  const { webWalletData, updateWebWalletData } = useWebWallet();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useSetState<ITokensAndNFTsResponse>({} as ITokensAndNFTsResponse);

  const propIsRequiredChainInfo = props?.isRequiredChainInfo || false;
  const propChainName = props?.chainName || webWalletData.chainName;
  const propChainId = Number(props?.chainId || webWalletData.chainId);

  if (propIsRequiredChainInfo && (!propChainName || !propChainId)) {
    return {
      loading: false,
      data: {
        chainId: propChainId,
        chainName: propChainName,
        exchangeRateData: [],
        nfts: [],
        tokens: [],
        allTokens: [],
        totalPrice: 0,
        loading: false,
        walletAddress: webWalletData.address,
        importTokenList: [],
      } as ITokensAndNFTsResponse,
      run: () => {},
      cancel: () => {},
      runAsync: () => {},
      refresh: () => {},
      getImpoertTokenList: () => {},
      getImpoertTokenListRequest: () => {},
      customTokenList: [],
    };
  }

  const cacheKey = `getTokens_${propChainName}_${propChainId}_${webWalletData.address}`.toLowerCase();
  const documentVisibility = useDocumentVisibility();

  const { data: impoertTokenList = [], run: getImpoertTokenListRequest, runAsync: getImpoertTokenList, updataImpoertTokenList } = useImpoertTokenList();

  const customTokenList = useMemo(() => {
    if (
      webWalletData.isLogin &&
      webWalletData.chainInfo &&
      webWalletData.chainInfo?.chainName?.toLowerCase() === webWalletData.particleWallet.chainName.toLowerCase() &&
      propChainId == Number(webWalletData.particleWallet.chainId) &&
      webWalletData.particleWallet &&
      impoertTokenList?.length
    ) {
      const { chainName, chainId } = impoertTokenList[0] || {};
      if (window.__webWalletData.particleWallet.chainName.toLowerCase() == chainName.toLowerCase() && Number(window.__webWalletData.particleWallet.chainId) === Number(chainId)) {
        return impoertTokenList;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [webWalletData.isLogin, webWalletData.chainInfo, webWalletData.particleWallet, impoertTokenList]);

  const getTokensAndNFTs = () => {
    const cachedData = window.cacheData[cacheKey];

    if (cachedData && cachedData.updateTime && new Date().getTime() - new Date(cachedData.updateTime.replace(/\s/, 'T')).getTime() <= 1000 * 60 * 5) {
      setData({
        ...(cachedData.data || {}),
        loading: false,
      } as ITokensAndNFTsResponse);
      setLoading(false);
    } else {
      setLoading(true);
      setData({
        chainId: propChainId,
        chainName: propChainName,
        exchangeRateData: [],
        nfts: [],
        tokens: [],
        allTokens: [],
        totalPrice: 0,
        loading: true,
        walletAddress: webWalletData.address,
        importTokenList: [],
      } as ITokensAndNFTsResponse);
    }
  };

  const options = {
    manual: true,
    pollingInterval,
    pollingWhenHidden,
    loadingDelay: 200,
    debounceWait: 20,
    onFinally: () => {
      setLoading(false);
    },
    onSuccess: (data: any) => {
      if (typeof data === 'undefined') {
        data = {};
      }
      if (!data?.tokens) {
        data.tokens = [];
      }
      const updatedData = {
        data,
        updateTime: new Date().toISOString(),
      };
      window.cacheData[cacheKey] = updatedData;
      setData({
        ...(updatedData?.data || {}),
        loading: false,
      });

      // add addCustomTokenList
      getImpoertTokenList().then((res) => {});

      setTimeout(() => {
        updateWebWalletData({ useRequestCache: true });
      }, 100);
    },
    onError: (error: any) => {
      if (error.message && !error.message.includes('while converting number undefined to BN.js') && !window.__webWalletData.showBraveShieldModal) {
        message.error(error.message);
      }
      console.log('err', error);
    },
  };

  const {
    run: _run,
    cancel,
    runAsync: _runAsync,
    refresh,
  } = useRequest(async () => {
    return webWalletData.particleWallet?.getTokensAndNFTs(propChainName, propChainId).then((data: ITokensAndNFTsResponse) => {
      // 处理 displayTokenAddresses displayNFTContractAddresses priorityNFTContractAddresses priorityTokenAddresses start
      let tokens = data?.tokens || [];
      let nfts = data?.nfts || [];

      // @ts-ignore
      if (Number(window.__appParams.paramsChainId) === propChainId) {
        // @ts-ignore
        let { displayTokenAddresses, displayNFTContractAddresses, priorityNFTContractAddresses, priorityTokenAddresses } = window.__appParams;
        displayTokenAddresses = displayTokenAddresses?.map?.((item: any) => item.toLowerCase()) || [];
        displayNFTContractAddresses = displayNFTContractAddresses?.map?.((item: any) => item.toLowerCase()) || [];
        priorityNFTContractAddresses = priorityNFTContractAddresses?.map?.((item: any) => item.toLowerCase()) || [];
        priorityTokenAddresses = priorityTokenAddresses?.map?.((item: any) => item.toLowerCase()) || [];

        if (displayTokenAddresses?.length) {
          // 只显示 displayTokenAddresses and native
          tokens = tokens.filter((item: any) => item.address == 'native' || displayTokenAddresses.includes(item.address.toLowerCase()));
        }

        if (displayNFTContractAddresses?.length) {
          // 只显示 displayNFTContractAddresses
          nfts = nfts.filter((item: any) => displayNFTContractAddresses.includes(item.address.toLowerCase()));
        }

        if (priorityTokenAddresses?.length) {
          // 优先显示 priorityTokenAddresses
          let priorityTokens = tokens.filter((item: any) => priorityTokenAddresses.includes(item.address.toLowerCase()));
          priorityTokens = priorityTokens.sort((a: any, b: any) => {
            const aIndex = priorityTokenAddresses.indexOf(a.address.toLowerCase());
            const bIndex = priorityTokenAddresses.indexOf(b.address.toLowerCase());
            return aIndex - bIndex;
          });
          const otherTokens = tokens.filter((item: any) => !priorityTokenAddresses.includes(item.address.toLowerCase()));
          tokens = [...priorityTokens, ...otherTokens];
        }

        if (priorityNFTContractAddresses?.length) {
          // 优先显示 priorityNFTContractAddresses
          let priorityNFTs = nfts.filter((item: any) => priorityNFTContractAddresses.includes(item.address.toLowerCase()));
          priorityNFTs = priorityNFTs.sort((a: any, b: any) => {
            const aIndex = priorityNFTContractAddresses.indexOf(a.address.toLowerCase());
            const bIndex = priorityNFTContractAddresses.indexOf(b.address.toLowerCase());
            return aIndex - bIndex;
          });
          const otherNFTs = nfts.filter((item: any) => !priorityNFTContractAddresses.includes(item.address.toLowerCase()));
          nfts = [...priorityNFTs, ...otherNFTs];

          data.tokens = tokens;
          data.nfts = nfts;
        }
      }
      // 处理 displayTokenAddresses displayNFTContractAddresses priorityNFTContractAddresses priorityTokenAddresses end
      return {
        ...data,
        loading: false,
      };
    });
  }, options);

  const run = async () => {
    await getSecurityMethod();
    _run();
  };

  const runAsync = async () => {
    await getSecurityMethod();
    return await _runAsync();
  };

  useEffect(() => {
    getSecurityMethod().then(() => {
      updataImpoertTokenList(window.__webWalletData, data, impoertTokenList);
    });
  }, [data, impoertTokenList]);

  useEffect(() => {
    if (webWalletData.isLogin && propChainName && propChainId && webWalletData.address) {
      getTokensAndNFTs();
    }
  }, [webWalletData.isLogin, propChainName, propChainId, webWalletData.address]);

  useEffect(() => {
    if (documentVisibility === 'visible') {
      run();
    }
  }, [documentVisibility]);

  return { loading, data, run, cancel, runAsync, refresh, getImpoertTokenList, getImpoertTokenListRequest, customTokenList };
};

export default useTokensAndNFTs;
