import { isServer } from '@/utils/env-util';
import { toHexAddress } from '@particle-network/auth';

// @ts-ignore
const t = isServer() ? (val) => val : window.t;

export function validBase58PublicKey(value: string) {
  const patt = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
  if (!value) {
    // @ts-ignore
    return Promise.reject(new Error(window.t('it_cant_empty')));
  } else {
    if (patt.test(value)) {
      return Promise.resolve();
    } else {
      // @ts-ignore
      return Promise.reject(new Error(window.t('invalid_format')));
    }
  }
}

export function validSolanaAddress(value: string) {
  const patt = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
  if (!value) {
    return false;
  } else {
    return patt.test(value);
  }
}

// base58String
export function validBase58String(value: string) {
  const patt = /^[A-HJ-NP-Za-km-z1-9]*$/;
  if (!value) {
    // @ts-ignore
    return Promise.reject(new Error(window.t('it_cant_empty')));
  } else {
    if (patt.test(value)) {
      return Promise.resolve();
    } else {
      // @ts-ignore
      return Promise.reject(new Error(window.t('invalid_format')));
    }
  }
}

// evmAddress (token)
// export function validEvmAddressToken(value: string, emptyMessage: string = 'Token address can’t be empty.', validMessage: string = 'Token address must be a valid address') {
//   const patt = /^0x[a-fA-F0-9]{40}$/;
//   if (!value) {
//     return Promise.reject(new Error(emptyMessage));
//   } else {
//     if (patt.test(value)) {
//       return Promise.resolve();
//     } else {
//       return Promise.reject(new Error(validMessage));
//     }
//   }
// }

// address validator
export function validWalletAddress(value: string, chainType: string, chainName?: string) {
  const solPatt = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
  const evmPatt = /^0x[a-fA-F0-9]{40}$/;
  if (!value) {
    // @ts-ignore
    return Promise.reject(new Error(window.t('please_enter_address')));
  } else {
    if (chainType === 'solana') {
      if (solPatt.test(value)) {
        return Promise.resolve();
      } else {
        // @ts-ignore
        return Promise.reject(new Error(window.t('please_enter_correct_address')));
      }
    } else if (chainName && chainName?.toLowerCase() === 'tron') {
      // 判断  value 是否是一个 合法的 tron 地址  T 开头 base58 decode 就行
      try {
        toHexAddress(value);
        return Promise.resolve();
      } catch (error) {
        // @ts-ignore
        return Promise.reject(new Error(window.t('please_enter_correct_address')));
      }
    } else {
      if (evmPatt.test(value)) {
        return Promise.resolve();
      } else {
        // @ts-ignore
        return Promise.reject(new Error(window.t('please_enter_correct_address')));
      }
    }
  }
}

// evmAddress (wallet)
export function validEvmAddressWallet(value: string) {
  const patt = /^0x[a-fA-F0-9]{40}$/;
  if (!value) {
    // @ts-ignore
    return Promise.reject(new Error(window.t('wallet_address_cant')));
  } else {
    if (patt.test(value)) {
      return Promise.resolve();
    } else {
      // @ts-ignore
      return Promise.reject(new Error(window.t('invalid_wallet_address')));
    }
  }
}

// hexString
export function validHexString(value: string) {
  const patt = /^(0[xX])?[A-Fa-f0-9]+$/;
  if (!value) {
    // @ts-ignore
    return Promise.reject(new Error(window.t('it_cant_empty')));
  } else {
    if (patt.test(value)) {
      return Promise.resolve();
    } else {
      // @ts-ignore
      return Promise.reject(new Error(window.t('invalid_format')));
    }
  }
}


export function isValidTronAddress(value: string) {
  return /^[1-9A-HJ-NP-Za-km-z]{34}$/.test(value);
}