import { WebWalletData, useWebWallet } from '@/context/WebWalletContext';
import { ITokensAndNFTsResponse, NftInfo, TokenInfo, formatAmount, getCacheTokenList, setCacheTokenList, shakeZero, toNonExponential } from '@particle-network/wallet-core';
import { useDebounceEffect, useDeepCompareEffect } from 'ahooks';
import BigNumber from 'bignumber.js';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { getSecurityMethod } from '../utils';
import { IImpoertToken } from './useImpoertTokenList';
import useTokens from './useTokens';

export const getCacheToken = (chainName?: string, chainId?: number): { tokenList: any[]; addressList: string[] } => {
  chainName = (chainName || window.__webWalletData.chainName).toLowerCase();
  chainId = Number(chainId || window.__webWalletData.chainId);
  const tokenList = getCacheTokenList(undefined, chainName, chainId);
  let addresss = cloneDeep(tokenList || []).map((item: any) => item.address || item?.metaData?.address || 'native');
  addresss = addresss.filter((address: any) => !!address);
  addresss = [...new Set(addresss)];
  return {
    tokenList,
    addressList: addresss,
  };
};

/**
 * 获取安全可信的tokenAddressList
 *
 * securityToken:存在于 (tokensAndNFTsData.allTokens && selectedImportTokenList && webWalletData.displayTokenAddresses && webWalletData.priorityTokenAddresses)
 *
 * @param webWalletData
 * @param allTokens
 * @param customTokenList
 * @returns
 */
export const getSecurityTokenAddressList = (webWalletData: WebWalletData, allTokens: any, customTokenList: IImpoertToken[]): string[] => {
  let securityTokenAddressList = new Set([
    'native',
    ...(webWalletData.displayTokenAddresses || []).map((address) => address.toLowerCase()),
    ...((webWalletData.priorityTokenAddresses || []).map((address) => address.toLowerCase()) || []),
  ]);

  allTokens?.tokens?.forEach((item: any) => {
    securityTokenAddressList.add(item.address.toLowerCase());
  });

  customTokenList
    // .filter((item: any) => item.isSelected)
    .filter((item: any) => item.isCustom)
    .forEach((item: any) => {
      securityTokenAddressList.add(item.address?.toLowerCase?.() || item.metaData?.address?.toLowerCase?.());
    });
  return [...securityTokenAddressList];
};

/**
 * sortTokens
 *
 * 合并，过滤，排序
 *
 * @param tokensAndNFTsData
 * @param customTokenList
 * @description
 * Token排序逻辑调整 2022-08-30
 * 首页-token列表：Native在首位，优先按 剩余按照持有的美元价值进行排序，无价格按照持有数量进行排序；没数量的按照添加的顺序进行排序，新添加的在前
 * @returns
 */
const sortTokens = (tokensAndNFTsData: ITokensAndNFTsResponse, customTokenList: IImpoertToken[], webWalletData: WebWalletData, chainName: string, chainId: number): TokenInfo[] => {
  let _tokenList: TokenInfo[] = [];
  tokensAndNFTsData = cloneDeep(tokensAndNFTsData);
  customTokenList = cloneDeep(customTokenList || []);
  if (tokensAndNFTsData?.tokens && tokensAndNFTsData?.tokens.length) {
    _tokenList = [...tokensAndNFTsData.tokens];

    // 合并 customTokenList selected token
    const selectedImportTokenList = cloneDeep((customTokenList || []).filter((item: any) => item.isSelected).map((item: any) => ({ ...item.metaData, updateTime: item.updateTime })) || []);

    if (selectedImportTokenList && selectedImportTokenList.length) {
      // 整理数据
      selectedImportTokenList.forEach((item: any) => {
        item.image = item.image || item.logoURI;
        if (item.amount) {
          item.amount = new BigNumber(item.amount || 0).dividedBy(new BigNumber(10 ** (item.decimals ?? 18))).toFixed(14 || item.decimals || 18, 1);
          item.amount = shakeZero(item.amount);
        } else {
          item.amount = 0;
        }

        item.mint = item.address;
        if (!_tokenList.find((o: any) => o.address?.toLowerCase?.() == item.address?.toLowerCase?.())) {
          _tokenList.push({ ...item, amount: 0 });
        }
      });
    }

    // 过滤 displayTokenAddresses
    // @ts-ignore
    if (Number(window.__appParams.paramsChainId) === chainId) {
      if (webWalletData.displayTokenAddresses && webWalletData.displayTokenAddresses.length && webWalletData.chainInfo.chainType == 'evm') {
        _tokenList = _tokenList.filter((item) => {
          return item.address == 'native' || webWalletData.displayTokenAddresses?.includes(item.address.toLowerCase());
        });
      }
    }

    // 过滤掉 unselected token
    const uncheckTokenList = (customTokenList || []).filter((item: any) => !item.isSelected).map((item: any) => item.metaData.address);
    _tokenList = _tokenList.filter((item: any) => !uncheckTokenList.includes(item.address));

    // 如果开启了隐藏可疑代币并且是主网，过滤掉_tokenList里面的可疑代币 start
    if (webWalletData.hideSuspiciousTokens && webWalletData.chainInfo.chainNetwork.toLowerCase() === 'mainnet') {
      let securityTokenAddressList = getSecurityTokenAddressList(webWalletData, tokensAndNFTsData.allTokens, customTokenList);
      _tokenList = _tokenList.filter((item: any) => {
        return securityTokenAddressList.some((address: string) => address.toLowerCase() === item.address.toLowerCase());
      });
    }
    // 如果开启了隐藏可疑代币并且是主网，过滤掉_tokenList里面的可疑代币 end

    // address 去重
    _tokenList = _tokenList.filter((item: any, index: number) => {
      return _tokenList.findIndex((o: any) => o.address?.toLowerCase?.() == item.address?.toLowerCase?.()) === index;
    });

    // 排序：Native在首位，优先按 剩余按照持有的美元(price)价值进行排序，无价格按照持有数量(amount)进行排序；没数量的按照更新时间(updateTime)进行排序，新添加的在前
    _tokenList = cloneDeep(_tokenList)
      .sort((a: any, b: any) => {
        a.price = Number(a.price) || 0;
        b.price = Number(b.price) || 0;
        a.amount = Number(a.amount) || 0;
        b.amount = Number(b.amount) || 0;

        if (a.address?.toLowerCase?.() == 'native') {
          return -1;
        }
        if (b.address?.toLowerCase?.() == 'native') {
          return 1;
        }

        if (a.price && b.price) {
          return b.price - a.price;
        }
        if (a.price && !b.price) {
          return -1;
        }
        if (!a.price && b.price) {
          return 1;
        }
        if (a.amount && b.amount) {
          return b.amount - a.amount;
        }
        if (a.amount && !b.amount) {
          return -1;
        }
        if (!a.amount && b.amount) {
          return 1;
        }
        if (a.updateTime && b.updateTime) {
          return new Date(b.updateTime).getTime() - new Date(a.updateTime).getTime();
        }
        if (a.updateTime && !b.updateTime) {
          return -1;
        }
        if (!a.updateTime && b.updateTime) {
          return 1;
        }
        return 0;
      })
      .map((item: any) => item.address)
      .map((address: string) => {
        return _tokenList.find((item: any) => item.address == address);
      }) as TokenInfo[];
  }

  // 如果 webWalletData.priorityTokenAddresses 里面有值，就把 priorityTokenAddresses 里面的 token 放到最前面,但是 native token 一定要放在最前面
  // @ts-ignore
  if (Number(window.__appParams.paramsChainId) === chainId && webWalletData.priorityTokenAddresses && webWalletData.priorityTokenAddresses.length) {
    const priorityTokenAddresses = webWalletData.priorityTokenAddresses.map((address: string) => address.toLowerCase());
    const nativeToken = _tokenList.find((item) => item.address == 'native');
    let priorityTokenList = _tokenList.filter((item) => priorityTokenAddresses?.includes(item.address.toLowerCase()));
    priorityTokenList = priorityTokenList.sort((a, b) => {
      const aIndex = priorityTokenAddresses?.indexOf(a.address.toLowerCase());
      const bIndex = priorityTokenAddresses?.indexOf(b.address.toLowerCase());
      return aIndex - bIndex;
    });

    // 不包含 native token
    const otherTokenList = _tokenList.filter((item) => item.address != 'native' && !priorityTokenAddresses?.includes(item.address.toLowerCase()));

    _tokenList = [nativeToken, ...priorityTokenList, ...otherTokenList].filter((address) => !!address) as TokenInfo[];
  }

  // 整理数据 用于页面展示
  _tokenList.forEach((item: TokenInfo) => {
    let exchangeRateData = tokensAndNFTsData?.exchangeRateData?.find((o: any) => o.address.toLowerCase() == item.address.toLowerCase());
    if (exchangeRateData) {
      item['24hChange'] = Number(toNonExponential(exchangeRateData['24hChange'], 2));
      item.oPrice = item.price;
      item.price = formatAmount((item?.amount || 0) * exchangeRateData.price, 4);
      item.doubleUnitPrice = formatAmount(((item?.amount || 0) * exchangeRateData.price).toString(), item.decimals ?? 18);
      item.unitPrice = formatAmount(exchangeRateData.price, 2);
      item.uPrice = exchangeRateData.price;
    } else {
      item.oPrice = item.price = 0;
      item['24hChange'] = 0;
      item.price = 0;
      item.doubleUnitPrice = 0;
      item.uPrice = 0;
    }
  });

  return _tokenList;
};

interface IProps {
  chainId?: number;
  chainName?: string;
  isRequiredChainInfo?: boolean;
}

export default (props?: IProps) => {
  const [tokenList, setTokenList] = useState<TokenInfo[]>([]);
  const [nftList, setNftList] = useState<NftInfo[]>([]);

  const { webWalletData, updateWebWalletData } = useWebWallet();

  const propIsRequiredChainInfo = props?.isRequiredChainInfo || false;
  const propChainName = props?.chainName || webWalletData.chainName;
  const propChainId = props?.chainId || Number(webWalletData.chainId);

  if (propIsRequiredChainInfo && (!propChainName || !propChainId)) {
    return {
      tokenList: [],
      tokensAndNFTsData: {
        chainName: propChainName,
        chainId: propChainId,
        allTokens: [],
        nfts: [],
        tokens: [],
        importTokenList: [],
        totalPrice: 0,
        walletAddress: webWalletData.address,
        exchangeRateData: [],
        loading: false,
      },
      cancelTokensAndNFTsRequest: () => {},
      tokensAndNFTsRequestAsync: () => {},
      tokensAndNFTsRequestLoading: false,
      tokensAndNFTsRequest: () => {},
      tokensAndNFTsRefresh: () => {},
      getImpoertTokenList: () => {},
      customTokenList: [],
    };
  }

  const {
    data: tokensAndNFTsData,
    cancel: cancelTokensAndNFTsRequest,
    loading: tokensAndNFTsRequestLoading = false,
    run,
    runAsync,
    getImpoertTokenList,
    refresh,
    customTokenList,
  } = useTokens({ chainName: propChainName, chainId: propChainId, isRequiredChainInfo: propIsRequiredChainInfo });

  const tokensAndNFTsRequest = async () => {
    await getSecurityMethod();
    run();
  };

  const tokensAndNFTsRequestAsync = async () => {
    await getSecurityMethod();
    return await runAsync();
  };

  useDeepCompareEffect(() => {
    let nftList: NftInfo[] = [];
    if (tokensAndNFTsData?.nfts?.length) {
      nftList = tokensAndNFTsData?.nfts;
      // 如果开启了隐藏可疑NFT，过滤掉 list 里面的可疑NFT start
      if (webWalletData.hideSuspiciousNfts) {
        nftList = nftList.filter((item: NftInfo) => {
          return item.isScam !== true;
        });
      }
      // 如果开启了隐藏可疑NFT，过滤掉 list 里面的可疑NFT start
    }
    setNftList(nftList);
  }, [tokensAndNFTsData?.nfts, webWalletData.hideSuspiciousNfts, webWalletData.chainInfo.chainNetwork]);

  useDeepCompareEffect(() => {
    if (tokensAndNFTsData?.chainName?.toLowerCase() == propChainName?.toLowerCase() && tokensAndNFTsData?.chainId == propChainId) {
      if (customTokenList) {
        let tokenList = cloneDeep(sortTokens(tokensAndNFTsData, customTokenList, webWalletData, propChainName, propChainId));
        if (tokenList?.length) {
          setCacheTokenList(tokenList, undefined, propChainName, propChainId);
        }
        setTokenList(cloneDeep(tokenList));
      }
    }
  }, [
    tokensAndNFTsData.tokens,
    tokensAndNFTsData?.chainId,
    tokensAndNFTsData.exchangeRateData,
    customTokenList,
    webWalletData.chainName,
    webWalletData.hideSuspiciousTokens,
    propChainName,
    propChainId,
  ]);

  // 更新 余额
  useDebounceEffect(
    () => {
      let balance = -1;
      const addressList = cloneDeep(tokenList || [])?.map((item) => item.address.toLowerCase()) || [];
      let tokens = tokensAndNFTsData?.tokens?.filter((item: any) => {
        return item.address == 'native' || addressList.includes(item.address.toLowerCase());
      });

      if (tokens && tokens.length) {
        let totalPrice: number =
          tokens.reduce((total: number, item: any) => {
            return item.price + total;
          }, 0) || 0;
        balance = Number(toNonExponential(totalPrice || 0, 2));
      }

      try {
        //@ts-ignore
        if (!tokens.length || tokens.find((item) => item.address == 'native')?.symbol?.toLowerCase?.() !== window.__webWalletData.chainInfo.symbol.toLowerCase()) {
          balance = -1;
        }
      } catch (error) {
        balance = -1;
      }

      updateWebWalletData({
        balance,
      });
    },
    [tokenList, tokensAndNFTsData.tokens],
    {
      wait: 100,
    }
  );

  return {
    tokenList,
    nftList,
    tokensAndNFTsData,
    cancelTokensAndNFTsRequest,
    tokensAndNFTsRequestAsync,
    tokensAndNFTsRequestLoading,
    tokensAndNFTsRequest,
    tokensAndNFTsRefresh: refresh,
    getImpoertTokenList,
    customTokenList,
  };
};
