import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { useWebWallet } from '@/context/WebWalletContext';
import { ITransactions } from '@particle-network/wallet-core';
import { useDocumentVisibility, useRequest } from 'ahooks';
import md5 from 'md5';
import qs from 'qs';
import { useEffect, useLayoutEffect, useState } from 'react';
import { getSecurityMethod, isServer } from '../utils';

interface IProps {
  pollingInterval: number;
  pollingWhenHidden: boolean;
}

const getTokenAddress = () => {
  // @ts-ignore
  return isServer() ? 'native' : qs.parse(window?.location.href?.split?.('?')?.pop?.()).tokenAddress || 'native';
};

const useTransactions = ({ pollingInterval = 10000, pollingWhenHidden }: IProps) => {
  const { webWalletData } = useWebWallet();
  const id = md5(`${webWalletData.chainName}_${webWalletData.chainId}_${webWalletData.address}`.toLowerCase());
  const documentVisibility = useDocumentVisibility();
  const [loading, setLoading] = useState(true);
  const sessionKey = `transactions-${id}`;
  const [transactions, setTransactions] = useState<ITransactions[]>(window.cacheData?.[sessionKey]?.list || []);
  const { selectedGlobalChain } = useUniversalAccount();

  const getCacheTransactions = () => {
    const cachedData = window.cacheData[sessionKey];
    const tokenAddress = getTokenAddress() as string;
    // if (webWalletData.chainName.toLowerCase() !== 'solana' && !window.cacheData[sessionKey]?.list?.length) {
    //   database.tansactionInfoList.get(id).then((res: any) => {
    //     if (res?.list?.length) {
    //       setTransactions(res.list as ITransactions[]);
    //       window.cacheData[sessionKey] = {
    //         list: res.list || [],
    //       };
    //       if (res?.list?.length) {
    //         setLoading(false);
    //       }
    //     } else {
    //       setTransactions([]);
    //       setLoading(true);
    //     }
    //   });
    // }
    if (cachedData) {
      let newList = cachedData.list;

      // 过滤 tokenAddress start
      if (webWalletData.chainInfo.chainType === 'evm' && tokenAddress !== 'native') {
        newList = newList.filter((item: any, index: number) => {
          return (
            item.to?.toLowerCase?.() === tokenAddress?.toLowerCase?.() ||
            item.from?.toLowerCase?.() === tokenAddress?.toLowerCase?.() ||
            !!item.deserializedData?.estimatedChanges?.tokens?.find((o: any) => {
              return o.address.toLowerCase() === tokenAddress?.toLowerCase?.();
            })
          );
        });
      } else if (webWalletData.chainInfo.chainType === 'solana' && tokenAddress !== 'native') {
        newList = newList.filter((item: any, index: number) => {
          return (
            item.to?.toLowerCase?.() === tokenAddress?.toLowerCase?.() ||
            item.from?.toLowerCase?.() === tokenAddress?.toLowerCase?.() ||
            item?.data?.receiver?.toLowerCase?.() === tokenAddress?.toLowerCase?.() ||
            item?.data?.sender?.toLowerCase?.() === tokenAddress?.toLowerCase?.() ||
            item?.data?.mint?.toLowerCase?.() === tokenAddress?.toLowerCase?.()
          );
        });
      }
      // 过滤 tokenAddress end

      setTransactions(newList);
      if (newList?.length) {
        setLoading(false);
      }
    } else {
      setTransactions([]);
      setLoading(true);
    }
  };

  const { run: _run, cancel } = useRequest(
    (...args) => {
      return webWalletData.particleWallet?.getTransactionsList(args[0] ?? 'native', false).then((res) => {
        return res;
      });
    },
    {
      manual: true,
      pollingInterval,
      pollingWhenHidden,
      onFinally: () => {
        setLoading(false);
      },
      onSuccess: (response) => {
        window.cacheData[sessionKey] = { list: response };
        getCacheTransactions();
      },
    }
  );

  const run = async (...args: any[]) => {
    // @ts-ignore
    (await getSecurityMethod(_run))(...args);
  };

  useLayoutEffect(() => {
    if (webWalletData.isLogin && webWalletData.address && webWalletData.chainId && webWalletData.chainName && webWalletData.address) {
      getCacheTransactions();
    }
  }, [webWalletData.isLogin, webWalletData.address, webWalletData.chainId, webWalletData.chainName, webWalletData.address]);

  useEffect(() => {
    if (selectedGlobalChain) {
      cancel();
    } else if (documentVisibility === 'visible') {
      run();
    }
  }, [documentVisibility, selectedGlobalChain]);

  return { loading, data: transactions, run, cancel };
};

export default useTransactions;
