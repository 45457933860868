import { BuyButton } from '@/components/buy/index';
import { MiniLink } from '@/components/miniLink';
import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { SwtichNetworkStatus, useWebWallet } from '@/context/WebWalletContext';
import useAccountAndSecurity from '@/hooks/useAccountAndSecurity';
import useTokensAndNFTsRequest from '@/hooks/useTokensAndNFTsRequest';
import useTransactions from '@/hooks/useTransactions';
import { PageType } from '@/types/index';
import { getCurrencySymbol } from '@/utils/index';
import { EVM_SWAP_NATIVE_ADDDRESS, NftInfo, SWAP_CHAIN_LIST, WSOL_TOKEN_ADDRESS, defaultTokenIcon, subString } from '@particle-network/wallet-core';
import { Button, Image, Popover, Skeleton, Tabs, message } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import qs from 'qs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useKeepAliveMountEffect } from 'react-next-keep-alive';
import Chat from '../Chat';
import { ReceiveIconButton, SendIconButton, SwapIconButton } from '../IconButtons';
import CameraPage from '../cameraPage';
import DeployWalletContract from '../deployWalletContract';
import DoubleArrows from '../doubleArrows';
import MIcon from '../mIcon';
import { getAAIcon } from '../smartAccount';
import { TokenDetailPageMode, TokenDetailTabKeys } from '../tokenDetail';
import ActivityList from '../tokenDetail/activityList';
import ActivityList2 from '../tokenDetail/activityList2';
import styles from './index.module.scss';
import NftList from './modules/nftList';
import ReceiveAddress from './modules/receiveAddress';
import SwtichChain from './modules/swtichChain';
import TabToken from './modules/tabToken';
import UpgradeAccount from './modules/upgradeAccount/index';

export enum TabKeys {
  Token = 'token',
  Nft = 'Nft',
  Activity = 'Activity',
  Tool = 'Tool',
}

export const isMainnet = (webWalletData: any) => {
  return webWalletData?.chainInfo?.chainNetwork?.toLowerCase() == 'mainnet';
};

export const getSwapUrl = (webWalletData: any, inputMint: string) => {
  let url = '';
  if (!!SWAP_CHAIN_LIST.find((item: any) => item.chainId == webWalletData.chainInfo.chainId && item.chainName.toLowerCase() == webWalletData.chainInfo.chainName.toLowerCase())) {
    if (!inputMint) {
      inputMint = 'native';
    }
    if (webWalletData.chainInfo.chainType == 'solana' && inputMint == 'native') {
      inputMint = WSOL_TOKEN_ADDRESS;
    } else if (webWalletData.chainInfo.chainType == 'evm' && inputMint == 'native') {
      inputMint = EVM_SWAP_NATIVE_ADDDRESS;
    }
    if (inputMint) {
      url = `/swap.html?inputMint=${inputMint}&outputMint=`;
    }
    if (inputMint) {
      const query = {
        inputMint,
        chainName: webWalletData.chainInfo.chainName,
        chainId: webWalletData.chainInfo.chainId,
        chainType: webWalletData.chainInfo.chainType,
        previous: encodeURIComponent(location.pathname + location.search),
        outputMint: '',
      };
      url = `/swap.html?${qs.stringify(query)}`;
    }
  }

  return url;
};

export const SwapButton = (props: any) => {
  const { loading } = props;
  const router = useRouter();

  const { selectedGlobalChain } = useUniversalAccount();

  const { webWalletData } = useWebWallet();

  const { t } = useTranslation();

  const swapUrl: any = useMemo(() => {
    if (selectedGlobalChain) {
      return '';
    }
    return getSwapUrl(webWalletData, (router?.query?.tokenAddress || '') as string);
  }, [webWalletData.isLogin, webWalletData.chainInfo, selectedGlobalChain]);

  return !loading ? (
    <div
      className={'item ' + (swapUrl ? '' : 'disabled')}
      onClick={() => {
        if (swapUrl) {
          webWalletData.clearPageCache(PageType.Swap);
          router.push(swapUrl);
        }
      }}
    >
      {/* <div className="icon icon4"></div> */}
      {/* <MIcon name="icon_9" /> */}
      <SwapIconButton />
      <div className="name">{t('swap')}</div>
      {!!swapUrl && <MiniLink href={swapUrl} />}
    </div>
  ) : (
    <div className="item">
      <Skeleton.Avatar size={40} active={true} />
      <div className="name">{t('swap')}</div>
    </div>
  );
};

const Index = (props: any) => {
  const router = useRouter();

  const { appParams } = props;

  const { t } = useTranslation();

  const defaultTab = ((qs.parse(location.search.replace(/^\?/, ''))?.tab as string) || '').toLowerCase();

  const [tabActiveKey, setTabActiveKey] = useState<TabKeys>(defaultTab === TabKeys.Nft.toLowerCase() ? TabKeys.Nft : defaultTab === TabKeys.Activity.toLowerCase() ? TabKeys.Activity : TabKeys.Token);

  const receiveAddressRef = useRef<any>();

  const { webWalletData } = useWebWallet();

  const { universalTokens, universalTokensLoading, uaBalance, selectedGlobalChain, universalTransactionList, universalTransactionLoading, universalTransactionRefresh } = useUniversalAccount();

  const { tokenList, nftList, tokensAndNFTsData, cancelTokensAndNFTsRequest, tokensAndNFTsRequestLoading, tokensAndNFTsRequest, getImpoertTokenList } = useTokensAndNFTsRequest();

  const [addressPopoverVisible, setAddressPopoverVisible] = useState(false);

  const tokenListLoading = useMemo(() => {
    if (selectedGlobalChain) {
      return universalTokensLoading;
    } else {
      return tokensAndNFTsRequestLoading;
    }
  }, [tokensAndNFTsRequestLoading, selectedGlobalChain, universalTokensLoading]);

  // 不可去掉
  const { displayAccountAndSecurityMenu, showPasswordRedDot } = useAccountAndSecurity();

  const {
    data: transactionList = [],
    run: getTransactionsList,
    cancel: cancelGetTransactionsList,
    loading: transactionLoading,
  } = useTransactions({
    pollingInterval: 10000,
    pollingWhenHidden: false,
  });

  const newTransactionList = useMemo(() => {
    if (selectedGlobalChain) {
      return universalTransactionList;
    } else {
      return transactionList;
    }
  }, [selectedGlobalChain, transactionList, universalTransactionList]);

  const newTransactionLoading = useMemo(() => {
    if (selectedGlobalChain) {
      return universalTransactionLoading;
    } else {
      return transactionLoading;
    }
  }, [selectedGlobalChain, transactionLoading, universalTransactionLoading]);

  const walletName = useMemo(() => {
    // @ts-ignore
    if (webWalletData?.accountAbstraction?.name === 'UNIVERSAL') {
      return 'Universal Account';
    }
    return webWalletData.accountAbstraction ? t('smart_account') : 'Particle Wallet';
  }, [webWalletData.accountAbstraction]);

  const currentChainBalance = useMemo(() => {
    if (selectedGlobalChain) {
      return uaBalance;
    } else {
      return webWalletData.balance;
    }
  }, [selectedGlobalChain, webWalletData.balance, uaBalance]);

  /**
   * 是否支持 Bridge
   */
  const isSupportBridge = useMemo(() => {
    if (selectedGlobalChain) {
      return false;
    }
    return webWalletData.supportBridgeChains?.includes(Number(webWalletData.chainId));
  }, [webWalletData.supportBridgeChains, webWalletData.chainId, selectedGlobalChain]);

  useKeepAliveMountEffect(PageType.Index, () => {
    webWalletData.clearPageCache(PageType.Send);
    webWalletData.clearPageCache(PageType.NftSend);
    webWalletData.clearPageCache(PageType.Swap);
    webWalletData.clearPageCache(PageType.Bridge);
    webWalletData.clearPageCache(PageType.NftBatchSend);
    webWalletData.clearPageCache(PageType.TokenBatchSend);
    webWalletData.clearPageCache(PageType.TokenDetail);
  });

  useEffect(() => {
    if (
      ((!selectedGlobalChain && webWalletData.swtichNetwork == SwtichNetworkStatus.init) || webWalletData.swtichNetwork == SwtichNetworkStatus.success) &&
      webWalletData.isLogin &&
      webWalletData.particleWallet &&
      (router.pathname == '/' || router.pathname == '')
    ) {
      cancelTokensAndNFTsRequest();
      setTimeout(() => {
        getImpoertTokenList();
        tokensAndNFTsRequest();
      });
    } else {
      cancelTokensAndNFTsRequest();
    }
  }, [webWalletData.isLogin, router.pathname, webWalletData.useRequestCache, webWalletData.swtichNetwork, selectedGlobalChain]);

  useEffect(() => {
    if (
      (webWalletData.swtichNetwork == SwtichNetworkStatus.init || webWalletData.swtichNetwork == SwtichNetworkStatus.success) &&
      webWalletData.isLogin &&
      webWalletData.particleWallet &&
      (router.pathname == '/' || router.pathname == '') &&
      tabActiveKey == TabKeys.Activity
    ) {
      cancelGetTransactionsList();
      setTimeout(() => {
        getTransactionsList('native');
      });
    } else {
      cancelGetTransactionsList();
    }
  }, [webWalletData.isLogin, router.pathname, webWalletData.useRequestCache, webWalletData.swtichNetwork, tabActiveKey]);

  useEffect(() => {
    if (selectedGlobalChain && tabActiveKey !== TabKeys.Activity && tabActiveKey !== TabKeys.Token) {
      setTabActiveKey(TabKeys.Token as TabKeys);
    }

    if (selectedGlobalChain && tabActiveKey === TabKeys.Activity) {
      universalTransactionRefresh();
    }
  }, [tabActiveKey, selectedGlobalChain, universalTransactionRefresh]);

  const tabItems = useMemo(() => {
    let items = [
      {
        label: t('token'),
        key: TabKeys.Token,
        children: <TabToken loading={tokenListLoading} tokenList={selectedGlobalChain ? universalTokens || [] : tokenList} />,
      },
    ];

    if (!selectedGlobalChain) {
      items.push({
        label: 'NFT',
        key: TabKeys.Nft,
        children: <NftList loading={tokenListLoading} nftList={nftList as NftInfo[]} />,
      });
    }

    items.push({
      label: t('activity'),
      key: TabKeys.Activity,
      children: (
        <div className="activity-wrap">
          {webWalletData.chainInfo.chainType === 'solana' ? (
            <ActivityList tabActiveKey={TokenDetailTabKeys.All} pageMode={TokenDetailPageMode.Default} transactionList={newTransactionList} transactionLoading={newTransactionLoading} />
          ) : (
            <ActivityList2 tabActiveKey={TokenDetailTabKeys.All} pageMode={TokenDetailPageMode.Default} transactionList={newTransactionList} transactionLoading={newTransactionLoading} />
          )}
        </div>
      ),
    });

    if (webWalletData.isAA && !selectedGlobalChain) {
      items.push({
        label: t('tool'),
        key: TabKeys.Tool,
        children: (
          <div className={styles.toolContainer}>
            <div className="tools-wrap">
              {webWalletData.isAA && (
                <>
                  <Link href="/chooseNFTs.html">
                    <a className="item">
                      <div className="icon">
                        <Image src={require('@/images/icon_44.png')} fallback={defaultTokenIcon} preview={false} />
                      </div>
                      <div className="title">{t('nft_batch_send')}</div>
                    </a>
                  </Link>
                  <Link href="/chooseTokens.html">
                    <a className="item">
                      <div className="icon">
                        <Image src={require('@/images/icon_45.png')} fallback={defaultTokenIcon} preview={false} />
                      </div>
                      <div className="title">{t('token_batch_send')}</div>
                    </a>
                  </Link>
                </>
              )}
              {/* {webWalletData.supportBridgeChains?.includes(Number(webWalletData.chainId)) && (
                <Link href="/bridge.html">
                  <a className="item">
                    <div className="icon">
                      <MIcon name={'icon_53'} size={20} />
                    </div>
                    <div className="title">{t('bridge')}</div>
                  </a>
                </Link>
              )} */}
            </div>
          </div>
        ),
      });
    }
    return items;
  }, [
    selectedGlobalChain,
    tabActiveKey,
    tokenListLoading,
    tokenList,
    tokensAndNFTsData,
    webWalletData.accountAbstraction,
    transactionList,
    transactionLoading,
    webWalletData.isAA,
    universalTokens,
    newTransactionList,
  ]);

  useEffect(() => {
    if (webWalletData.isLogin && tabActiveKey === TabKeys.Tool && !webWalletData.isAA) {
      setTabActiveKey(TabKeys.Token);
    }
    // @ts-ignore
    window.__message = message;
  }, [webWalletData.isLogin, tabActiveKey, webWalletData.isAA]);

  useEffect(() => {
    if (addressPopoverVisible) {
      setTimeout(() => {
        document.querySelectorAll('.address-list-popover .ant-popover-content').forEach((item) => {
          item.classList.add('show1');
        });
      }, 100);
    } else {
      document.querySelectorAll('.address-list-popover .ant-popover-content').forEach((item) => {
        item.classList.remove('show1');
      });
    }
  }, [addressPopoverVisible]);

  return (
    // <Div100vh>
    <div className="index-container scrollbar">
      <Chat />
      <div className="wrapper">
        <div className="index-header" data-theme={webWalletData.theme}>
          <div className="left">
            <DoubleArrows />
            <div className="wrap">
              <SwtichChain />
            </div>
          </div>
          <div className="right">
            <CameraPage />
            {!!(webWalletData.swtichNetwork != SwtichNetworkStatus.loading && webWalletData.chainInfo.icon) ? (
              <MiniLink href="/setting.html">
                <div className={`setting-icon ${webWalletData.displayAccountAndSecurityMenu && webWalletData.showPasswordRedDot ? 'show-red-dot' : "'"}`}>
                  <MIcon name="icon_27" size={30} />
                </div>
              </MiniLink>
            ) : (
              <div className="setting-skeleton">
                <Skeleton.Avatar size={30} active={true} />
              </div>
            )}
          </div>
        </div>
        <div className="main-content">
          <div className="wallet-info-content">
            <UpgradeAccount />
            <div className="product-name">
              {webWalletData.accountAbstraction && webWalletData.particleWallet?.enableAccountAbstraction?.() && (
                <div className="aa-tag">
                  <Image
                    src={getAAIcon((webWalletData.accountAbstraction as any).name, webWalletData.theme)}
                    key={getAAIcon((webWalletData.accountAbstraction as any).name, webWalletData.theme)}
                    fallback={defaultTokenIcon}
                    preview={false}
                    width={22}
                    height={22}
                  />
                </div>
              )}
              <span>{walletName}</span>
            </div>
            <div className="address-copy">
              {webWalletData.address ? (
                <>
                  <div className="wrap">
                    <CopyToClipboard
                      text={webWalletData.copyAddress || webWalletData.address}
                      onCopy={() => {
                        message.success(t('copied_to'));
                      }}
                    >
                      <div className="copy-wrap">
                        <span>{subString(webWalletData.address)}</span>
                        {webWalletData.address && <MIcon name="icon_80" className="copy-icon" />}

                        {webWalletData.isBtcConnect && (
                          <Popover
                            overlayClassName="address-list-popover"
                            getPopupContainer={() => {
                              return document.body;
                            }}
                            content={
                              <div
                                className="address-list"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                              >
                                <CopyToClipboard
                                  text={webWalletData.copyAddress || webWalletData.address}
                                  onCopy={() => {
                                    message.success(t('copied_to'));
                                  }}
                                >
                                  <div className="item">
                                    <div className="label">EVM AA</div>
                                    <div className="right">
                                      <div className="address">{subString(webWalletData.copyAddress || webWalletData.address)}</div>
                                      <MIcon name="icon_80" className="copy-icon" />
                                    </div>
                                  </div>
                                </CopyToClipboard>
                                {!!webWalletData.btcAddress && (
                                  <CopyToClipboard
                                    text={webWalletData.btcAddress}
                                    onCopy={() => {
                                      message.success(t('copied_to'));
                                    }}
                                  >
                                    <div className="item">
                                      <div className="label">BTC</div>
                                      <div className="right">
                                        <div className="address">{subString(webWalletData.btcAddress)}</div>
                                        <MIcon name="icon_80" className="copy-icon" />
                                      </div>
                                    </div>
                                  </CopyToClipboard>
                                )}
                              </div>
                            }
                            placement="bottom"
                            trigger="click"
                            open={addressPopoverVisible}
                            onOpenChange={setAddressPopoverVisible}
                          >
                            <div
                              className="address-arrow-wrap"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            >
                              {<MIcon name="icon_arrow2" className={`address-arrow ${addressPopoverVisible ? 'open' : ''}`} />}
                            </div>
                          </Popover>
                        )}
                      </div>
                    </CopyToClipboard>
                  </div>
                </>
              ) : (
                <Skeleton.Input active={true} className="skeleton-rc-5" />
              )}
            </div>
            <div className="account-info-Nx9">
              {Number(currentChainBalance) >= 0 ? (
                <>
                  <div className="row row1">
                    {getCurrencySymbol(webWalletData.currency)}
                    {currentChainBalance}
                  </div>
                  <div className="row row2">
                    {t('total_asset')} (≈{webWalletData.currency})
                  </div>
                </>
              ) : (
                <Skeleton.Input active={true} className="skeleton-rc-5" />
              )}
            </div>
            <div className="operate-content">
              {/* Send Button*/}
              {!tokenListLoading ? (
                <MiniLink className="item" href={`/send.html?tokenAddress=native&tokenType=${(router?.query?.tokenType as unknown as string) || ''}`}>
                  <SendIconButton size={40} />
                  <div className="name">{t('send')}</div>
                </MiniLink>
              ) : (
                <div className="item">
                  <Skeleton.Avatar size={40} active={true} />
                  <div className="name">{t('send')}</div>
                </div>
              )}

              {/* Receive Button*/}
              {!tokenListLoading ? (
                <div
                  className="item"
                  onClick={() => {
                    if (receiveAddressRef.current) {
                      receiveAddressRef.current?.setVisible(true);
                    }
                  }}
                >
                  <ReceiveIconButton size={40} />
                  <div className="name">{t('receive')}</div>
                </div>
              ) : (
                <div className="item">
                  <Skeleton.Avatar size={40} active={true} />
                  <div className="name">{t('receive')}</div>
                </div>
              )}

              {/* Buy Button*/}
              <BuyButton loading={tokenListLoading} />

              {/* Swap Button */}
              <SwapButton loading={tokenListLoading} />

              {!selectedGlobalChain && (
                <>
                  {/* Bridge Button */}
                  {!tokenListLoading ? (
                    <div
                      className={`item ${isSupportBridge ? '' : 'disabled'}`}
                      onClick={() => {
                        if (webWalletData.supportBridgeChains.includes(Number(webWalletData.chainId))) {
                          webWalletData.clearPageCache(PageType.Bridge);
                          router.push('/bridge.html');
                        }
                      }}
                    >
                      <div className="icon-button-default">
                        <MIcon name="icon_55" size={40} />
                      </div>
                      <div className="name">{t('bridge')}</div>
                    </div>
                  ) : (
                    <div className="item">
                      <Skeleton.Avatar size={40} active={true} />
                      <div className="name">{t('bridge')}</div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="list-content" data-theme={webWalletData.theme}>
            <Tabs
              data-theme={webWalletData.theme}
              activeKey={tabActiveKey}
              // destroyInactiveTabPane={true}
              tabBarExtraContent={
                !tokenListLoading && [TabKeys.Token, TabKeys.Nft].includes(tabActiveKey) && appParams.supportAddToken && !selectedGlobalChain ? (
                  <MiniLink href={tabActiveKey === TabKeys.Token ? '/tokenList.html' : '/nftList.html'}>
                    <div className="token-add-btn" data-type={tabActiveKey === TabKeys.Token ? 'token' : 'nft'}>
                      <div className="icon">
                        <MIcon name="icon_11" size={20} />
                      </div>
                    </div>
                  </MiniLink>
                ) : null
              }
              onChange={(tabActiveKey) => {
                setTabActiveKey(tabActiveKey as TabKeys);
              }}
              items={tabItems}
            />
          </div>
          {[TabKeys.Token, TabKeys.Nft].includes(tabActiveKey) && appParams.supportAddToken ? (
            <div className="import-token-btn" data-type={tabActiveKey === TabKeys.Token ? 'token' : 'nft'}>
              <MiniLink href={tabActiveKey === TabKeys.Token ? '/tokenList.html' : '/nftList.html'}>
                <Button type="link">
                  <div className="icon">
                    <MIcon name="icon_11" size={20} />
                  </div>
                  <span>{tabActiveKey === TabKeys.Token ? t('import_token') : t('import_nft')}</span>
                </Button>
              </MiniLink>
            </div>
          ) : (
            ''
          )}
          <ReceiveAddress ref={receiveAddressRef} address="native" />
        </div>
      </div>
      <DeployWalletContract />
    </div>
    // </Div100vh>
  );
};

export default Index;
