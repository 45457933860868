import { useWebWallet } from '@/context/WebWalletContext';
import { useConnectId } from '@particle-network/connect-react-ui';
import { iframeWallet } from '@particle-network/iframe-wallet';
import { dataBase } from '@particle-network/wallet-core';
import { useDebounceEffect, useRequest } from 'ahooks';
import { message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import i18n from '../i18n';
import { getUserInfo } from '../utils';

const useAccountAndSecurity = () => {
  const connectId = useConnectId();

  const { webWalletData, updateWebWalletData } = useWebWallet();

  const [showPasswordRedDot, setShowPasswordRedDot] = useState(false);

  const [displayAccountAndSecurityMenu, setDisplayAccountAndSecurityMenu] = useState(false);

  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (webWalletData.inAuthCoreIframe) {
      iframeWallet
        .getUserInfo()
        .then((info) => setUserInfo(info))
        .catch((error) => {
          // thirdparty wallet, ignore error
        });
    }
  }, [webWalletData.inAuthCoreIframe]);

  const { runAsync: setSystemSettingInfo } = useRequest(
    (userId, systemSettingInfo) => {
      return dataBase.systemSetting.put({
        userId,
        ...systemSettingInfo,
      });
    },
    {
      manual: true,
    }
  );

  const { data: userSimpleInfo, run: getUserSimpleInfo } = useRequest(
    (params) => {
      return webWalletData.particleWallet.getUserSimpleInfo(params).then((res: any) => {
        if (res.data.error_code) {
          throw new Error(`${res.data.message}_${res.data.error_code}`);
        } else if (!res?.data?.security_account) {
          throw new Error('get user simple info error');
        }
        return res?.data?.security_account || {};
      });
    },
    {
      manual: true,
      onSuccess: (result) => {
        console.log('user simple info', result);
        getUserInfo().then((userInfo) => {
          //@ts-ignore
          window.particle.auth.setUserInfo({ ...userInfo, security_account: result });
        });
      },
      onError: (error: any) => {
        console.log('err', error);
        if (error && error.message && error.message.includes('10005')) {
          message.error(i18n.t('server_error_10005') || error.message);
          setTimeout(() => {
            webWalletData.logout({ type: 'invalid' });
          }, 2000);
        }
      },
    }
  );

  const {
    loading: systemSettingInfoLoading = true,
    data: systemSettingInfo,
    run: getSystemSettingInfo,
  } = useRequest(
    (userId) => {
      return dataBase.systemSetting.where({ userId }).first();
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    // @ts-ignore
    if (window.logoutFlag !== true) {
      if (
        webWalletData.isLogin &&
        ((webWalletData.inAuthCoreIframe && (webWalletData.supportCustomEvent === true || webWalletData.supportCustomEvent === 'auth')) || // auth core iframe
          (!webWalletData.inAuthCoreIframe && webWalletData.loginType === 'session') || // auth iframe
          (!webWalletData.inAuthCoreIframe && webWalletData.loginType !== 'session' && connectId === 'particle')) // 正常页面 && particle
      ) {
        setDisplayAccountAndSecurityMenu(true);
      } else {
        setDisplayAccountAndSecurityMenu(false);
      }
    }
  }, [connectId, webWalletData.loginType, webWalletData.isLogin, userInfo, webWalletData.inAuthCoreIframe]);

  useDebounceEffect(
    () => {
      getUserInfo().then((userInfo) => {
        if (!userInfo) {
          return;
        }
        // const userId = userInfo.uuid;
        const securitySccount = userInfo?.security_account || {};

        const hasSetPaymentPassword = (securitySccount['has_set_payment_password'] && securitySccount['has_set_master_password']) ?? false;

        let showPasswordRedDot = !hasSetPaymentPassword;
        if (showPasswordRedDot) {
          // 从数据库查找 ，如果查找到 并且不是当前天的日期就显示红点，否则不显示
          if (
            webWalletData.isLogin &&
            !systemSettingInfoLoading &&
            (!systemSettingInfo?.securityRedDotUpdateDate || (systemSettingInfo?.securityRedDotUpdateDate && systemSettingInfo.securityRedDotUpdateDate != dayjs(new Date()).format('YYYY-MM-DD')))
          ) {
            showPasswordRedDot = true;
          } else {
            showPasswordRedDot = false;
          }
        }
        setShowPasswordRedDot(showPasswordRedDot);
      });
    },
    [userSimpleInfo, systemSettingInfo, webWalletData.isLogin],
    {
      wait: 100,
    }
  );

  useDebounceEffect(
    () => {
      (async () => {
        if (webWalletData.isLogin && displayAccountAndSecurityMenu && webWalletData?.particleWallet) {
          if (webWalletData.inAuthCoreIframe) {
            // if (userInfo) {
            //   // @ts-ignore
            //   const userInfoKey = window.particle?.auth?.concatStorageKey(window.particle.auth.PN_AUTH_USER_INFO);
            //   localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
            // }
            // @ts-ignore
          } else if (!window.particle.auth.getUserInfo()?.token) {
            webWalletData.logout();
          } else {
            getUserSimpleInfo({
              // @ts-ignore
              token: window.particle.auth.getUserInfo().token,
              chainId: webWalletData.chainId,
              projectKey: webWalletData.projectId,
              projectAppUuid: webWalletData.appId,
              apiUrl: process.env.NEXT_PUBLIC_API_URL as string,
            });
          }
        }
      })();
    },
    [webWalletData.isLogin, displayAccountAndSecurityMenu, webWalletData.particleWallet, webWalletData.projectId, webWalletData.appId, webWalletData.chainId, webWalletData.inAuthCoreIframe, userInfo],
    {
      wait: 100,
    }
  );

  useEffect(() => {
    updateWebWalletData({ displayAccountAndSecurityMenu });
  }, [displayAccountAndSecurityMenu]);

  useEffect(() => {
    updateWebWalletData({ showPasswordRedDot });
  }, [showPasswordRedDot]);

  useEffect(() => {
    if (webWalletData.isLogin) {
      getUserInfo().then((userInfo) => {
        const userId = userInfo?.uuid;
        if (userId) {
          getSystemSettingInfo(userId);
        }
      });
    }
  }, [webWalletData.isLogin]);

  return {
    displayAccountAndSecurityMenu,
    setSystemSettingInfo,
    getSystemSettingInfo,
    systemSettingInfo,
    getUserSimpleInfo,
    userSimpleInfo,
    showPasswordRedDot,
  };
};

export default useAccountAndSecurity;
