import { MiniLink } from '@/components/miniLink';
import NoRecord from '@/components/noRecord';
import { useWebWallet } from '@/context/WebWalletContext';
import { NftInfo } from '@particle-network/wallet-core';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import NftImage from './nftImage';

interface IProps {
  loading: boolean;
  nftList: NftInfo[];
}
const NftList = forwardRef((props: IProps, ref) => {
  const { loading, nftList } = props;
  const { webWalletData } = useWebWallet();
  const { t } = useTranslation();

  return (
    <div className="nft-list-container">
      {!loading && nftList?.length ? (
        <div className="nft-list">
          {nftList.map((item: NftInfo, index: number) => {
            return (
              <MiniLink
                className="item"
                data-1155={item.is1155}
                data-key={webWalletData.chainName + '_' + webWalletData.chainId + '_' + (item.address || item.tokenId) + '_' + index}
                key={webWalletData.chainName + '_' + webWalletData.chainId + '_' + (item.address || item.tokenId) + '_' + index}
                href={`/nftDetail.html?address=${item?.address}&tokenId=${item.tokenId || ''}`}
              >
                <div className="hidden">{JSON.stringify(item, null, 2)}</div>
                <NftImage src={item?.image} isScam={item.isScam} animationUrl={item.animationUrl} displayErrorText={false} />
                <div className="info-content">
                  <div className="name" title={item?.data?.name || item?.name}>
                    {item?.data?.name || item?.name || 'NFT ' + item?.tokenId}
                  </div>
                </div>
              </MiniLink>
            );
          })}
        </div>
      ) : (
        <NoRecord description={t('as_you_get')} title={loading ? t('loading') + '...' : ''} />
      )}
      {/* </Spin> */}
    </div>
  );
});

export default NftList;
