import { useWebWallet } from '@/context/WebWalletContext';
import { EvmService } from '@particle-network/wallet-core';
import { Button, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WModel from '../WModel';

const DeployWalletContract = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { webWalletData } = useWebWallet();
  const { t } = useTranslation();

  useEffect(() => {
    webWalletData.events.on('deployWalletContractRequest', () => {
      setOpen(true);
      setLoading(false);
    });
    return () => {
      webWalletData.events.removeAllListeners('deployWalletContractRequest');
    };
  }, [webWalletData.events]);

  const onClose = () => {
    setOpen(false);
    setLoading(false);
    webWalletData.events.emit('deployWalletContractResult', false);
  };

  const deployWallet = async () => {
    setLoading(true);
    try {
      // @ts-ignore
      await (webWalletData.particleWallet as EvmService)?.deployWalletContract();
      setOpen(false);
      setLoading(false);
      webWalletData.events.emit('deployWalletContractResult', true);
    } catch (error: any) {
      setLoading(false);
      if (error?.message) {
        message.error(error?.message);
        console.log('err', error);
      }
    }
  };

  return (
    <WModel className="deploy-wallet-contract-modal deploy-wallet-contract-drawer" visible={open} onClose={onClose}>
      <div className="deploy-wallet-content">
        <div className="deploy-tip">{t('deploy_wallet_contract_hint')}</div>
        <div className="bootom-action">
          <Button className="btn-modal" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button type="primary" className="btn-modal" onClick={deployWallet} loading={loading}>
            {t('deploy_setup')}
          </Button>
        </div>
        <div className="wallet_footer">
          <img className="logo_logo" src="/images/walletConnect/logo_logo.png" />
        </div>
      </div>
    </WModel>
  );
};

export default DeployWalletContract;
