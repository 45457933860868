import ActivityIcon from '@/components/activityList/ActivityIcon';
import { MiniLink } from '@/components/miniLink';
import NoRecord from '@/components/noRecord';
import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { useWebWallet } from '@/context/WebWalletContext';
import more from '@/images/icon_more.svg';
import { ITransactions, TransactionsType, UniversalTransaction } from '@particle-network/wallet-core';
import { Skeleton } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { TokenDetailPageMode, TokenDetailTabKeys } from '..';
import styles from './index.module.scss';
interface IProps {
  tabActiveKey: TokenDetailTabKeys;
  pageMode: TokenDetailPageMode;
  transactionList: ITransactions[] | UniversalTransaction[];
  transactionLoading: boolean;
}

const useBreakAll = (value: string) => {
  // 如果有一个单词长度超过12位就返回true
  return value.split(' ').some((item) => item.length > 12);
};

export const getActivityActionName = (transactionData: ITransactions, amountView = ''): 'out' | 'in' | 'none' => {
  if (transactionData?.deserializedType == TransactionsType.RSS3_NFT_APPROVE || transactionData?.deserializedType == TransactionsType.RSS3_ERC_20_APPROVE) {
    return 'none';
  }
  amountView = amountView || transactionData?.amountChangeList?.[0]?.amountView;
  return amountView?.charAt(0) == '-' ? 'out' : 'in';
};

const ActivityList = (props: IProps) => {
  const { tabActiveKey, transactionList, transactionLoading } = props;
  const router = useRouter();
  const { webWalletData, t, updateWebWalletData } = useWebWallet();
  const [debug, setDebug] = useState(false);
  const { selectedGlobalChain } = useUniversalAccount();

  const tokenAddress = useMemo(() => {
    return (router.query.tokenAddress || 'native') as string;
  }, [router.query.tokenAddress]);

  const recordList = useMemo(() => {
    // 过滤
    let _recordList = [...transactionList];

    if (tabActiveKey === TokenDetailTabKeys.Out) {
      _recordList = _recordList.filter((item) => item.action === 'out' && item.statusName !== 'failed');
    } else if (tabActiveKey === TokenDetailTabKeys.In) {
      _recordList = _recordList.filter((item) => item.action === 'in' && item.statusName !== 'failed');
    } else if (tabActiveKey === TokenDetailTabKeys.Failed) {
      _recordList = _recordList.filter((item) => item.statusName == 'failed');
    }

    // 简单列表 显示标题 start
    for (let i = 0; i < _recordList.length; i++) {
      if (i == 0 && (_recordList[i].status == 0 || _recordList[i].status == 1)) {
        _recordList[i].simpleTitle = 'pending';
      } else if (
        (i == 0 && _recordList[i].status !== 0 && _recordList[i].status !== 1) ||
        (i > 0 && (_recordList[i - 1].status == 0 || _recordList[i - 1].status == 1) && _recordList[i].status !== 0 && _recordList[i].status !== 1)
      ) {
        _recordList[i].simpleTitle = 'completed';
        break;
      }
    }
    // 简单列表 显示标题 end

    if (selectedGlobalChain) {
      _recordList.forEach((item) => {
        // @ts-ignore
        if (item?.sendChainIcons?.length > 1) {
          item.badge = more;
        }
      });
    }

    return _recordList;
  }, [transactionList, tabActiveKey, selectedGlobalChain]);

  useEffect(() => {
    if (webWalletData.isLogin && !transactionLoading) {
      updateWebWalletData({ useRequestCache: true });
    }
  }, [webWalletData.isLogin, transactionLoading]);

  useEffect(() => {
    if (router.query.debug) {
      setDebug(true);
    }
  }, [router.query]);

  return (
    <>
      {transactionLoading ? (
        <div className={styles['skeleton-record-list']}>
          {new Array(3).fill('').map((_, index) => {
            return <Skeleton avatar paragraph={{ rows: 1 }} active={false} key={index} />;
          })}
        </div>
      ) : (
        <div className={`${styles['record-list']}`}>
          {recordList && recordList.length ? (
            recordList.map((item, index: number) => {
              return (
                <div
                  className="item-wrap"
                  // @ts-ignore
                  data-key={item.hash + '-' + index + '-' + item.status + '-' + item?.dateValue + '-' + item?.id}
                  // @ts-ignore
                  key={item.hash + '-' + index + '-' + item.status + '-' + item?.dateValue + '-' + item?.id}
                >
                  <div
                    className={[styles['record-item'], 'type-' + item.iconType].join(' ')}
                    data-type={item.type}
                    onClick={() => {
                      if (selectedGlobalChain) {
                        // @ts-ignore
                        router.push(`/uaTransactionDetail.html?id=${item.id}&hash=${item.hash}&tokenAddress=` + tokenAddress);
                      } else {
                        router.push(`/transactionDetail.html?hash=${item.hash}&tokenAddress=` + tokenAddress);
                      }
                    }}
                  >
                    <div className="left">
                      <div className="col dd" data-aa={item.deserializedData?.type}>
                        {<ActivityIcon type={item.deserializedData?.type} badge={item.badge} />}
                      </div>
                      <div className="col col2">
                        <div className="type" data-type={item.deserializedData?.type} data-break-all={useBreakAll(item?.typeName || '')}>
                          {item.typeName}
                          {debug && <> &nbsp;&nbsp;||&nbsp;&nbsp;&nbsp;{item.dateValue}</>}
                        </div>
                        {item.pending ? (
                          <div className="status status-pending">Pending</div>
                        ) : item.statusName === 'failed' ? (
                          <div className="status status-failed">Failed</div>
                        ) : (
                          <div className="date">{item.dateValue}</div>
                        )}
                      </div>
                    </div>
                    <div className="right">
                      {item.deserializedData?.type === TransactionsType.RSS3_SWAP ? (
                        <>
                          {item.amountChangeList?.map((o, i) => {
                            return (
                              <div key={i}>
                                <div className="row1">{item.addressValue}</div>
                                <div className={`token-row swap-row action-${getActivityActionName(o, o?.amountView)}`}>
                                  <span className="token-amount">
                                    {o?.amountView}&nbsp;{o?.symbol}
                                  </span>
                                  {/* <span className="token-symbol">{item?.symbol}</span> */}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {item.deserializedData?.type !== TransactionsType.RSS3_NFT_MINT && item.deserializedData?.type !== TransactionsType.RSS3_ERC_20_BURN && !!item.addressValue && (
                            <>
                              {/* mint nft and burn 不显示 from address */}
                              <div className="row1">{item.addressValue}</div>
                            </>
                          )}

                          <div className={`row2 amount action-${getActivityActionName(item as any)}`}>
                            {item.deserializedData?.type === TransactionsType.RSS3_NFT_SEND ? (
                              <>
                                {/* send nft */}
                                {item.amountChangeList?.[0]?.amountView}&nbsp;
                                {item.amountChangeList?.[0]?.symbol || '?'}
                              </>
                            ) : item.deserializedData?.type === TransactionsType.RSS3_ERC_20_BURN ||
                              item.deserializedData?.type === TransactionsType.RSS3_ERC_20_APPROVE ||
                              item.deserializedData?.type === TransactionsType.RSS3_TOKEN_RECEIVE ? (
                              <>
                                <div className="token-row">
                                  {/* rss3_erc_20_burn */}
                                  {/* rss3_erc_20_approve */}
                                  {/* rss3_token_receive */}
                                  <span className="token-amount">{item.amountChangeList?.[0]?.amountView}</span>
                                  <span className="token-symbol">{item.amountChangeList?.[0]?.symbol}</span>
                                </div>
                              </>
                            ) : item.deserializedData?.type === TransactionsType.RSS3_NFT_APPROVE ||
                              item.deserializedData?.type === TransactionsType.RSS3_NFT_BUY ||
                              item.deserializedData?.type === TransactionsType.RSS3_NFT_MINT ? (
                              <>
                                {item.deserializedData?.type === TransactionsType.RSS3_NFT_BUY ? (
                                  <>+1 Particle Testnet Phase 2</>
                                ) : selectedGlobalChain ? (
                                  <>+1 Particle Pioneer NFT</>
                                ) : (
                                  <>
                                    {/* rss3_nft_approve */}
                                    {/* rss3_nft_buy */}
                                    {/* rss3_nft_mint */}
                                    <div className="nft-row">
                                      <span>
                                        {item.amountChangeList?.[0]?.amountView}
                                        {/* &nbsp;
                                    {item.amountChangeList?.[0]?.symbol} */}
                                        &nbsp;
                                        {item.amountChangeList?.[0]?.name}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : Number(item.amountChangeList?.[0]?.amountView) != 0 ? (
                              <>
                                {item.amountChangeList?.[0]?.amountView}&nbsp;
                                {item.amountChangeList?.[0]?.symbol}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <MiniLink href={`/transactionDetail.html?hash=${item.hash}&tokenAddress=` + tokenAddress} hidden={true} />
                  </div>
                </div>
              );
            })
          ) : (
            <NoRecord description={t('as_you_start')} />
          )}
        </div>
      )}
    </>
  );
};

export default ActivityList;
