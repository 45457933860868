import WModel from '@/components/WModel';
import MIcon from '@/components/mIcon';
import ScrollContainer from '@/components/scrollContainer';
import { getAAIcon } from '@/components/smartAccount';
import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { SwtichNetworkStatus, useWebWallet } from '@/context/WebWalletContext';
import useChainList from '@/hooks/useChainList';
import { ChainInfo } from '@particle-network/chains';
import { defaultTokenIcon, formatPng, getGlobalChain, getSupportedTARGETToken, uaChainOrder } from '@particle-network/wallet-core';
import { Button, Image, Input, Skeleton, Switch } from 'antd';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export enum SwitchChainType {
  switchNetwork = 'switchNetwork',
  chooseChain = 'chooseChain',
}

interface IProps {
  title?: string;
  source?: string;
  children?: React.ReactNode;
  type?: SwitchChainType;
  setChain?: (chain: ChainInfo) => void;
  chainId?: number;
  sendTokenType?: string;
}

const SwtichChain = forwardRef((props: IProps, ref) => {
  const { title, source, children, setChain, type = SwitchChainType.switchNetwork, chainId, sendTokenType } = props;
  const { t } = useTranslation();

  const { webWalletData, updateWebWalletData } = useWebWallet();

  const [visible, setVisible] = useState(false);

  const popoverChianListRef = useRef<HTMLDivElement>(null);

  const searchRef = useRef(null);

  const { chainList, showSwtichButton, displaySelectChainList: dsccl } = useChainList();

  const universalTargetChainList = useMemo(() => {
    return getSupportedTARGETToken().sort((a, b) => {
      const indexA = uaChainOrder.indexOf(a.chainId);
      const indexB = uaChainOrder.indexOf(b.chainId);

      if (indexA === -1 && indexB === -1) {
        return 0;
      } else if (indexA === -1) {
        return 1;
      } else if (indexB === -1) {
        return -1;
      } else {
        return indexA - indexB;
      }
    });
  }, []);

  const [displaySelectChainList, setDisplaySelectChainList] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const { selectedGlobalChain, setSelectedGlobalChain } = useUniversalAccount();

  const globalChain = getGlobalChain();

  const searchChainList = useMemo(() => {
    return (type == SwitchChainType.chooseChain && selectedGlobalChain ? universalTargetChainList.filter((item) => item.type === sendTokenType) : chainList)
      .filter((item: any) => {
        if (type == SwitchChainType.chooseChain && item.chainId == globalChain.chainId) {
          return false;
        }
        return (
          item?.chainId?.toString?.().includes(searchValue) ||
          item?.chainName?.toLowerCase?.().includes(searchValue.toLowerCase()) ||
          item?.fullname?.toLowerCase?.().includes(searchValue.toLowerCase()) ||
          item?.chainNetwork?.toString?.().includes(searchValue) ||
          item?.chainType?.toString?.().includes(searchValue) ||
          item?.symbol?.toLowerCase?.().includes(searchValue.toLowerCase())
        );
      })
      .map((item) => {
        if (type == SwitchChainType.chooseChain && chainId === item.chainId) {
          return {
            ...item,
            selected: true,
          };
        }
        return {
          ...item,
        };
      });
  }, [chainList, searchValue, type, chainId, selectedGlobalChain, sendTokenType]);

  const displaySearch = useMemo(() => {
    return chainList.length > 6;
  }, [chainList]);

  const currentChain = useMemo(() => {
    if (selectedGlobalChain) {
      const chain = getGlobalChain();
      return chain;
    } else {
      return webWalletData.chainInfo;
    }
  }, [selectedGlobalChain, webWalletData.chainInfo]);

  /**
   * 焦点设置和滚动到指定位置
   */
  const focusSearchInputAndScroll = () => {
    if (searchRef.current) {
      const scrollTop: number = popoverChianListRef?.current?.scrollTop || 0;
      (searchRef.current as HTMLInputElement).focus();
      popoverChianListRef?.current?.scrollTo(0, scrollTop);
    }

    if (popoverChianListRef.current) {
      const selectedDom = popoverChianListRef.current.querySelector?.('div[data-selected="true"]');
      if (selectedDom) {
        const selectedDomRect = selectedDom.getBoundingClientRect();
        const popoverChianListRect = popoverChianListRef.current.getBoundingClientRect();
        if (selectedDomRect.top < popoverChianListRect.top || selectedDomRect.bottom > popoverChianListRect.bottom) {
          selectedDom.scrollIntoView({ block: 'center', behavior: 'auto' });
        }
      }
    }
  };

  useEffect(() => {
    setDisplaySelectChainList(dsccl);
  }, [dsccl]);

  useEffect(() => {
    const handleAnimationEnd = () => {
      if (visible && !!popoverChianListRef.current) {
        focusSearchInputAndScroll();
      }
    };
    document.querySelector('.swaitch-chain-modal .ant-modal')?.addEventListener('animationend', handleAnimationEnd);
    return () => {
      document.querySelector('.swaitch-chain-modal .ant-modal')?.removeEventListener('animationend', handleAnimationEnd);
    };
  }, [visible, popoverChianListRef.current]);

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setSearchValue('');
      }, 300);
    }
  }, [visible]);

  return (
    <div className="switch-chain-container" data-source={source}>
      {!!title && (
        <div className="name">
          {webWalletData.accountAbstraction && webWalletData.particleWallet?.enableAccountAbstraction?.() && (
            <div className="aa-tag">
              <Image src={getAAIcon((webWalletData.accountAbstraction as any).name, webWalletData.theme)} fallback={defaultTokenIcon} preview={false} width={18} height={18} />
            </div>
          )}
          <span>{title}</span>
        </div>
      )}
      {children ? (
        <div
          onClick={() => {
            if (displaySelectChainList && webWalletData.swtichNetwork != SwtichNetworkStatus.loading && currentChain.icon) {
              setVisible(true);
            }
          }}
        >
          {children}
        </div>
      ) : (
        <>
          <div
            className="network type m-network"
            onClick={() => {
              if (displaySelectChainList && webWalletData.swtichNetwork != SwtichNetworkStatus.loading && currentChain.icon) {
                setVisible(true);
              }
            }}
          >
            {webWalletData.swtichNetwork != SwtichNetworkStatus.loading && currentChain.icon ? (
              <Image className="middle-type-icon" src={formatPng(currentChain.icon)} key={currentChain.icon} fallback={defaultTokenIcon} preview={false} />
            ) : (
              <div className="middle-type-icon">
                <Skeleton.Avatar size={11} shape="circle" active />
              </div>
            )}

            {
              <span>
                {webWalletData.swtichNetwork != SwtichNetworkStatus.loading && currentChain.icon ? (
                  <>{currentChain.fullname?.replace('Mainnet', '').trim()}</>
                ) : (
                  <div className="skeleton-wrap">
                    <Skeleton.Input active block={true} />
                  </div>
                )}
              </span>
            }
            {!!(displaySelectChainList && webWalletData.swtichNetwork != SwtichNetworkStatus.loading && currentChain.icon) && <div className="select-arrow"></div>}
          </div>
          <div className="pa-pc-network">
            <Button
              onClick={() => {
                if (displaySelectChainList) {
                  setVisible(true);
                }
              }}
            >
              <div className="icon1">
                {webWalletData.swtichNetwork != SwtichNetworkStatus.loading && currentChain.icon ? (
                  <Image className="chain-icon" src={formatPng(currentChain.icon)} key={currentChain.icon} fallback={defaultTokenIcon} preview={false} />
                ) : (
                  <Skeleton.Avatar size={12} shape="circle" active />
                )}
              </div>
              <span>
                {webWalletData.swtichNetwork != SwtichNetworkStatus.loading && webWalletData.chainInfo.icon ? (
                  <>{currentChain?.fullname?.replace('Mainnet', '').trim()}</>
                ) : (
                  <div className="skeleton-wrap">
                    <Skeleton.Input active block={true} />
                  </div>
                )}
              </span>
              {displaySelectChainList ? <MIcon name="icon_13" className="icon2" size={16} /> : <div className="icon3" />}
            </Button>
          </div>
        </>
      )}

      <WModel
        title={t('networks')}
        className="swaitch-chain-modal"
        visible={visible}
        modalProps={{ forceRender: true, style: {}, width: '400px' }}
        onClose={() => {
          setVisible(false);
        }}
      >
        <div>
          {displaySearch && (
            <div className="item search-input-content">
              <Input
                ref={searchRef}
                placeholder={t('search')}
                prefix={<MIcon name="icon_30" />}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                onFocus={() => {
                  document.querySelector('.search-input-content .ant-input-affix-wrapper')?.setAttribute('data-focus', 'true');
                }}
                onBlur={() => {
                  document.querySelector('.search-input-content .ant-input-affix-wrapper')?.setAttribute('data-focus', 'false');
                }}
              />
            </div>
          )}
          <ScrollContainer color="--bg-color-4">
            <div className="list" ref={popoverChianListRef}>
              {searchChainList.length == 0 && (
                <div className="item no-data">
                  <span className="name">{t('no_data')}</span>
                </div>
              )}
              {searchChainList.map((item: any, index: number) => {
                return (
                  <div
                    className="item"
                    key={item.chainName + '-' + item.chainId + '-' + index}
                    data-chainid={item.chainId}
                    data-selected={item.selected}
                    onClick={() => {
                      setTimeout(() => {
                        setVisible(false);
                      }, 100);

                      setChain && setChain?.(item);

                      // send page change chain
                      if (type == SwitchChainType.chooseChain) {
                        return;
                      }

                      if (globalChain.chainId === item.chainId) {
                        setSelectedGlobalChain(true);
                        return false;
                      } else {
                        setSelectedGlobalChain(false);
                      }

                      if (webWalletData.chainId == item.chainId && webWalletData.chainName.toLowerCase() == item.chainName.toLowerCase()) {
                        return false;
                      }

                      const chainId = item.chainId;
                      const chainName = item.chainName;

                      updateWebWalletData({
                        chainId,
                        chainName,
                        swtichNetwork: SwtichNetworkStatus.loading,
                      });
                    }}
                  >
                    <div className="left">
                      <Image className="left-img" src={formatPng(item.icon)} key={item.icon} alt={item.icon} fallback={defaultTokenIcon} preview={false} />
                      <span className="name">
                        {/* 如果 不显示测试网络 需要把fullname里面的 Mainnet 去掉 */}
                        {!webWalletData.showTestNetWork ? item.fullname?.replace('Mainnet', '').trim() : item.fullname}
                      </span>
                    </div>
                    <div className="right">
                      {item.selected ? (
                        <div className="right-icon">
                          <MIcon name="icon_18" size={18} />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </ScrollContainer>
          {showSwtichButton && (
            <div className="footer">
              <div className="switch-content">
                <span>{t('test_network')}</span>
                <Switch
                  defaultChecked
                  checked={webWalletData.showTestNetWork}
                  onChange={(e) => {
                    const showTestNetWork = e;
                    window.storage.setItem('showTestNetWork', e ? '1' : '');
                    updateWebWalletData({ showTestNetWork });
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </WModel>
    </div>
  );
});

export default SwtichChain;
