import more from '@/images/icon_more.svg';
import { LP_SUPPORTED_CHAINS } from '@particle-network/wallet-core';
import { Image } from 'antd';
import { useEffect, useMemo } from 'react';
import styles from './index.module.scss';

interface Token {
  icon: string;
  chainId: number;
  name: string;
}
interface Props {
  size?: number;
  tokens: Token[];
  limit?: number;
  showMore?: boolean;
}

const tokenSort = (tokens: Token[]) => {
  return tokens.sort((a, b) => {
    const aIndex = LP_SUPPORTED_CHAINS.indexOf(a.chainId);
    const bIndex = LP_SUPPORTED_CHAINS.indexOf(b.chainId);
    return aIndex - bIndex;
  });
};

const TokenIcons = (props: Props) => {
  let { tokens, limit = 5, size = 18, showMore = true } = props;
  tokens = tokenSort(tokens);

  /**
   * 唯一id
   */
  const id = useMemo(() => {
    return Math.random().toString(36).slice(2);
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.innerHTML = `.tokenIcons[data-id="${id}"] {--iconSize: ${size}px;}`;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, [size, id]);

  return (
    <div className="tokenIcons" data-id={id}>
      <div className={`${styles.tokenIcons} icons_${tokens?.length}`}>
        {tokens.slice(0, limit).map(({ icon }, index) => {
          return (
            <div className="icon_3dd12" key={index}>
              <Image key={index} className="token-icon" src={icon} preview={false} />
            </div>
          );
        })}
        {showMore && tokens.length > limit && (
          <div className="icon_3dd12">
            <Image className="token-icon" src={more} preview={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TokenIcons;
