interface IProps {
  wrapClassName?: string;
  children?: any;
  color?: string;
  style?: any;
}

const getCssVar = (key: string) => {
  return document.querySelector('.layout-main') && getComputedStyle(document.querySelector('.layout-main') as HTMLDivElement).getPropertyValue(key);
};

const scrollContainer = (props: IProps) => {
  const { wrapClassName, children } = props;

  const color = getCssVar(props.color || '--content-background-color');

  const upStyle = `linear-gradient(0deg, rgba(255, 255, 255, 0), ${color})`;

  const downStyle = `linear-gradient(180deg, rgba(255, 255, 255, 0), ${color})`;

  return (
    <div
      className={`${'scrollContainer'} ${wrapClassName}`}
      style={{
        ...props.style,
      }}
    >
      {children}
      <div
        className={`linear-gradient-up`}
        style={{
          background: upStyle,
        }}
      />
      <div
        className={`linear-gradient-down`}
        style={{
          background: downStyle,
        }}
      />
    </div>
  );
};

export default scrollContainer;
