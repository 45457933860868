import Loading from '@/components/loading';
import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { useWebWallet } from '@/context/WebWalletContext';
import { captureMessage, popupWindow, subString } from '@/utils/index';
import { getChainList } from '@particle-network/wallet-core';
import { useDeepCompareEffect, useSetState } from 'ahooks';
import { Skeleton, message } from 'antd';
import copy from 'copy-to-clipboard';
import { useRouter } from 'next/router';
import qs from 'qs';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BuyIconButton } from '../IconButtons';
import MIcon from '../mIcon';

export type OpencNetwork = 'Solana' | 'Ethereum' | 'Tron' | 'Arbitrum One' | 'Binance Smart Chain' | 'Polygon' | 'Optimism' | 'Avalanche';

interface OpencOptions {
  accountId?: string;
  clientType?: string;
  network?: OpencNetwork;
  fiatCoin?: string;
  cryptoCoin?: string;
  fiatAmt?: number;
  cryptAmt?: number;
  fixFiatCoin?: boolean;
  fixCryptoCoin?: boolean;
  fixFiatAmt?: boolean;
  merchantOrderId?: string;
  tradeType?: string;
  hideAddress?: boolean;
  walletAddress?: string;
  memo?: string;
  refundAddress?: string;
  refundMemo?: string;
  addressSign?: string;
  nonce?: string;
  theme?: string;
}

const Title = (params: OpencOptions) => {
  const { walletAddress, network } = params;

  const chainIcon = useMemo(() => {
    if (network) {
      let chainList = getChainList(true, '', '');
      const chainName = network === 'Binance Smart Chain' ? 'BSC' : network;
      const { icon } = chainList.find((item: any) => item.chainName.toLowerCase() === chainName?.toLowerCase()) || {};
      return icon;
    } else {
      return '';
    }
  }, [network]);

  const { t } = useTranslation();

  return (
    <>
      <div className="p-title">{t('buy_crypto')}</div>
      <div className="p-chaininfo">
        {!!chainIcon && <img key={chainIcon} src={chainIcon} alt="" />}
        <span>{network}</span>
      </div>
      <div className="p-address">
        <span>{subString(walletAddress || '')}</span>{' '}
        <div
          className="copy-icon"
          onClick={() => {
            if (typeof window !== 'undefined') {
              if (location.href.indexOf('https') === 0) {
                navigator.clipboard.writeText(walletAddress || '');
                message.success(t('copied_to'));
                return;
              }
            }
            copy(walletAddress || '');
            message.success(t('copied_to'));
          }}
        >
          <MIcon name="icon_14" className="copy-icon" />
        </div>
      </div>
    </>
  );
};

export const getBuyUrl = (params: OpencOptions) => {
  let url = 'https://openc.pro/widget-page/?widgetId=WG5hWm9wVVg';

  const query = qs.stringify(params);
  if (query.length > 0) {
    url += `&${query}`;
  }

  return url;
};

const Buy = (props: OpencOptions) => {
  const [loading, setLoading] = useState(true);

  const url = useMemo(() => {
    return getBuyUrl(props);
  }, [props]);

  return (
    <div className="buy-wrapper">
      {loading && <Loading spinning={loading} />}
      <iframe
        key={url}
        src={url}
        onLoad={() => {
          setTimeout(() => {
            setLoading(false);
          }, 100);
        }}
      />
    </div>
  );
};

// 支持ramp chainnames
enum RampNetwork {
  'solana' = 'Solana',
  'ethereum' = 'Ethereum',
  'bnb chain' = 'Binance Smart Chain',
  'polygon' = 'Polygon',
  'tron' = 'Tron',
  'arbitrum one' = 'Arbitrum One',
  'optimism' = 'Optimism',
  'avalanche' = 'Avalanche',
  'celo' = 'Celo',
  'zksync era' = 'Zksync',
  'sui' = 'Sui',
  'mantle' = 'Mantle',
  'base' = 'Base',
  'opbnb' = 'opBNB',
}

export const getOpencNetwork = (webWalletData: any): OpencNetwork => {
  if (webWalletData.chainInfo.chainNetwork !== 'Mainnet') {
    return 'Ethereum' as OpencNetwork;
  }
  const chainName = webWalletData.chainInfo.fullname.replace(' Mainnet', '').toLowerCase();
  const network = RampNetwork[chainName as keyof typeof RampNetwork] || 'Ethereum';
  return network as OpencNetwork;
};

/**
 * 传入chainName 根据OpencNetwork判断此chainName是否能购买
 */
export const isBuyChain = (webWalletData: any): boolean => {
  if (webWalletData.chainInfo.chainNetwork !== 'Mainnet') {
    return false;
  }

  const chainName = webWalletData.chainInfo.fullname.replace(' Mainnet', '').toLowerCase();
  return !!RampNetwork[chainName as keyof typeof RampNetwork];
};

export const BuyButton = ({ loading }: { loading?: boolean }) => {
  const { webWalletData } = useWebWallet();
  const [buyParams, setBuyParams] = useSetState<OpencOptions>({});
  const router = useRouter();
  const { selectedGlobalChain } = useUniversalAccount();

  const canBuy = useMemo(() => {
    if (selectedGlobalChain) {
      return false;
    } else if (webWalletData.isLogin) {
      return isBuyChain(webWalletData);
    } else {
      return false;
    }
  }, [webWalletData.isLogin, webWalletData?.chainInfo?.chainNetwork, webWalletData.chainName, webWalletData.chainInfo, selectedGlobalChain]);

  useEffect(() => {
    setBuyParams({
      network: getOpencNetwork(webWalletData),
      walletAddress: webWalletData.copyAddress,
      cryptoCoin: webWalletData.chainInfo.symbol, // symbol
      fiatCoin: webWalletData.currency, // currency
    });
  }, [webWalletData.isLogin, webWalletData.chainName, webWalletData.copyAddress, webWalletData.chainInfo.symbol, webWalletData.currency, webWalletData.chainInfo]);

  const { t } = useTranslation();

  return !loading ? (
    <div className={'item ' + (canBuy ? '' : 'disabled')}>
      <div
        className="wrap"
        onClick={() => {
          if (canBuy) {
            const rampUrl = process.env.NEXT_PUBLIC_RAMP_WEB_URL as string;
            const { copyAddress: address, currency, chainInfo, theme, language } = webWalletData;
            const { chainName, symbol, fullname } = chainInfo;

            const buyParams = {
              walletAddress: address,
              fiatCoin: currency,
              cryptoCoin: symbol,
              chainName: (fullname as string).replace('Mainnet', '').trim(),
              showBackButton: true,
              theme,
              language,
            };
            const newUrl = `${rampUrl}/?${qs.stringify(buyParams)}`;
            console.log('newUrl', newUrl);
            captureMessage('rampUrl', newUrl, 'info');
            // 判断当前页面是否处于Iframe中
            if (window.self !== window.top) {
              if (window.outerWidth > 600) {
                let height = parseInt((window.outerHeight * 0.6).toString());
                if (height < window.innerHeight) {
                  height = window.innerHeight;
                }

                let width = height * 0.6;
                if (width < window.innerWidth) {
                  width = window.innerWidth;
                }

                // @ts-ignore
                popupWindow(newUrl, 'Particle Network', width, height);
                // popupWindow(`/buy.html?${qs.stringify(buyParams)}`, 'Particle Network', width, height);
              } else {
                // router.push(newUrl);
                // router.push(`/buy.html?${qs.stringify(buyParams)}`);
                window.open(newUrl, '_blank');
              }
            } else if (webWalletData.viewType == 'desktop') {
              let height = parseInt((window.innerHeight * 0.8).toString());
              let width = height * 0.6;
              if (width > 1000) {
                width = 1000;
              }
              if (width > window.innerWidth) {
                width = parseInt((window.innerWidth * 0.9).toString());
              }
              popupWindow(newUrl, 'Particle Network', width, height);
              // popupWindow(`/buy.html?${qs.stringify(buyParams)}`, 'Particle Network', width, height);
            } else {
              // router.push(newUrl);
              // router.push(`/buy.html?${qs.stringify(buyParams)}`);
              window.open(newUrl, '_blank');
            }
          }
        }}
      >
        {/* <div className="icon icon3"></div> */}
        {/* <MIcon name="icon_10" /> */}
        <BuyIconButton />
        <div className="name">{t('buy')}</div>
      </div>
    </div>
  ) : (
    <div className="item">
      <Skeleton.Avatar size={40} active={true} />
      <div className="name">{t('buy')}</div>
    </div>
  );
};

const Page = () => {
  const router = useRouter();
  const [buyParams, setBuyParams] = useSetState<OpencOptions>({});
  const { webWalletData } = useWebWallet();

  // Ethereum
  // /buy.html?chainName=Ethereum&address=0x631571040DD9fe3BF37BDcA6b2731dbe2Ae8bA9E&cryptoCoin=ETH&fiatCoin=USD

  // Solana
  // /buy.html?chainName=Solana&address=DsyDZyM2VWBg7iZTyETZ1sT7FQs2dpMZ3t1C4Mj6h5vb&cryptoCoin=SOL&fiatCoin=USD

  useDeepCompareEffect(() => {
    const options = router.query;
    setBuyParams(options);
  }, [router.query]);

  return <></>;
};

export default memo(Page);
