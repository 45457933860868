import MIcon from '@/components/mIcon';
import { useWebWallet } from '@/context/WebWalletContext';
import { ipfsToSrc } from '@/utils/index';
import { Image as AntImage } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
let loadSuccessImageList: any[] = [];

interface IProps {
  isScam?: boolean;
  [key: string]: any;
}
const NftImage = (props: IProps) => {
  const { src, animationUrl, displayErrorText = false, lockedState = '', isScam } = props;
  const { webWalletData } = useWebWallet();
  const [imgLoadError, setImgLoadError] = useState<boolean>(false);
  const { t } = useTranslation();
  const loadingImg = `/images/nft/nft_loading_${webWalletData.theme === 'dark' ? '000' : 'fff'}.png`;
  const [imgSrc, setImgSrc] = useState<string>(loadingImg);
  const [imgType, setImgType] = useState<'square' | 'horizontal' | 'vertical' | ''>('square');
  const errorImg = useMemo(() => {
    // return `/images/nft/nft_error_${webWalletData.theme === 'dark' ? '000' : 'fff'}.png`;
    return `/images/nft/nft_loading_${webWalletData.theme === 'dark' ? '000' : 'fff'}.png`;
  }, [webWalletData.theme]);

  useEffect(() => {
    if (lockedState && lockedState == 'loading') {
      setImgSrc(loadingImg);
      return;
    }

    if (isScam && webWalletData.theme === 'dark') {
      setImgSrc(require(`@/images/scam_nft_000.png`));
    } else if (isScam) {
      setImgSrc(require(`@/images/scam_nft_fff.png`));
    } else if (src) {
      const cacheData = loadSuccessImageList.find((item) => item.src === src);
      if (cacheData) {
        const { type, src } = cacheData;
        setImgSrc(ipfsToSrc(src));
        setImgType(type);
        setImgLoadError(false);
        return;
      }
      const image = new Image();
      image.src = ipfsToSrc(src);
      // image.src = 'https://iph.href.lu/500x500';
      image.onload = (e: Event) => {
        setImgSrc(image.src || errorImg);
        setImgLoadError(false);
        const width = (e?.currentTarget as HTMLImageElement)?.width || 0;
        const height = (e?.currentTarget as HTMLImageElement)?.height || 0;
        const type: 'square' | 'horizontal' | 'vertical' = (() => {
          if (width > height) {
            return 'horizontal';
          } else if (width < height) {
            return 'vertical';
          } else {
            return 'square';
          }
        })();
        loadSuccessImageList.push({
          src,
          type,
        });
        setImgType(type);
      };
      image.onerror = () => {
        setImgSrc(errorImg);
        setImgLoadError(true);
        setImgType('square');
      };
    } else {
      setImgSrc(errorImg);
      setImgLoadError(true);
    }
  }, [src, webWalletData.theme, lockedState]);

  return (
    <div className={`img-content ${imgLoadError ? 'error-img' : ''}`} data-image={src}>
      <div className="img" data-src={ipfsToSrc(src)}>
        <AntImage src={imgSrc} data-type={imgType} key={imgSrc} fallback={errorImg} className="nft-img-1" preview={false} />
        {displayErrorText && imgLoadError && <div className="error-text">{t('this_nft_has_no_pictures')}</div>}
      </div>
      {animationUrl ? (
        <div className="icon-content">
          <MIcon className="3d-nft-icon" name="nft_icon" size={30} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default NftImage;
