import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IPorps {
  title?: string;
  description?: string;
  style?: React.CSSProperties;
}

const NoRecord = (props: IPorps) => {
  const { t } = useTranslation();
  const [theme, setTheme] = useState('light');
  useEffect(() => {
    // @ts-ignore
    setTheme(window.storage.getItem(window.__appParams?.themeKey) || 'light');
  }, []);
  const defatltDescription = (
    <>
      <div className="empty-title">{props.title || t('no_records')}</div>
      {props.description && <div className="empty-sub-title">{props.description}</div>}
    </>
  );
  return (
    <div className="no-record-container no-record" style={{ ...(props?.style || {}) }}>
      <Empty description={defatltDescription} />
    </div>
  );
};

export default NoRecord;
