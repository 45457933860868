import { useMemo } from 'react';
import MIcon from '../mIcon';

export const SendIconButton = (props: any) => {
  const style = useMemo(() => {
    let result = {};
    if (props.size) {
      result = {
        style: {
          width: props.size,
          height: props.size,
        },
      };
    }
    return result;
  }, [props.size]);
  return (
    <div className="icon-button-default" {...style}>
      <MIcon name="icon_36" />
    </div>
  );
};

export const ReceiveIconButton = (props: any) => {
  const style = useMemo(() => {
    let result = {};
    if (props.size) {
      result = {
        style: {
          width: props.size,
          height: props.size,
        },
      };
    }
    return result;
  }, [props.size]);
  return (
    <div className="icon-button-default" {...style}>
      <MIcon name="icon_33" />
    </div>
  );
};

export const BuyIconButton = (props: any) => {
  const style = useMemo(() => {
    let result = {};
    if (props.size) {
      result = {
        style: {
          width: props.size,
          height: props.size,
        },
      };
    }
    return result;
  }, [props.size]);
  return (
    <div className="icon-button-default" {...style}>
      <MIcon name="icon_37" />
    </div>
  );
};

export const SwapIconButton = (props: any) => {
  const style = useMemo(() => {
    let result = {};
    if (props.size) {
      result = {
        style: {
          width: props.size,
          height: props.size,
        },
      };
    }
    return result;
  }, [props.size]);
  return (
    <div className="icon-button-default" {...style}>
      <MIcon name="icon_35" />
    </div>
  );
};

export const WithdrawIconButton = (props: any) => {
  const style = useMemo(() => {
    let result = {};
    if (props.size) {
      result = {
        style: {
          width: props.size,
          height: props.size,
        },
      };
    }
    return result;
  }, [props.size]);
  return (
    <div className="icon-button-default" {...style}>
      <MIcon name="icon_34" />
    </div>
  );
};
