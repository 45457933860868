import { useWebWallet } from '@/context/WebWalletContext';
import { ImportTokenInfo } from '@particle-network/wallet-core';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { getSecurityMethod } from '../utils';

export interface IImpoertToken {
  [key: string]: any;
}

const useImpoertTokenList = () => {
  const { webWalletData } = useWebWallet();
  const [loading, setLoading] = useState(true);
  const [impoertTokenList, setImpoertTokenList] = useState<IImpoertToken[]>([]);
  const cacheKey = `getImpoertTokenList_${webWalletData.chainName}_${webWalletData.chainId}_${webWalletData.address}`.toLowerCase();

  const getCachedTransactions = () => {
    const cachedData = window.cacheData[cacheKey];
    if (cachedData && cachedData.updateTime && new Date().getTime() - new Date(cachedData.updateTime.replace(/\s/, 'T')).getTime() <= 1000 * 60 * 5) {
      setImpoertTokenList(cachedData.list);
      if (cachedData.list.length > 0) {
        setLoading(false);
      }
    } else {
      setImpoertTokenList([]);
    }
  };

  const { run: _run, runAsync: _runAsync } = useRequest(
    () => {
      return webWalletData.particleWallet
        ?.getImpoertTokenList?.({
          userAddress: webWalletData.address.toLowerCase(),
          chainNetwork: webWalletData.chainInfo.chainNetwork,
        })
        .then((tokenList) => {
          return tokenList;
        });
    },
    {
      manual: true,
      onFinally: () => {
        setLoading(false);
      },
      onSuccess: (res: any[]) => {
        const updatedData = {
          list: res,
          updateTime: new Date().toISOString(),
        };
        window.cacheData[cacheKey] = updatedData;
        setImpoertTokenList(updatedData.list);
      },
    }
  );

  const run = async () => {
    // @ts-ignore
    (await getSecurityMethod(_run))();
  };

  const runAsync = async () => {
    // @ts-ignore
    (await getSecurityMethod(_runAsync))();
  };

  const updataImpoertTokenList = (webWalletData: any, data: any, impoertTokenList: any[]) => {
    setTimeout(async () => {
      if (
        !impoertTokenList?.length ||
        (impoertTokenList?.length && impoertTokenList[0].chainName.toLowerCase() == data?.chainName?.toLowerCase?.() && Number(impoertTokenList[0].chainId) == Number(data?.chainId))
      ) {
        const customTokenList = impoertTokenList as any[];
        const { address: userAddress } = webWalletData || {};
        const { chainName, chainId, chainNetwork } = webWalletData.chainInfo || {};

        const _data = data?.tokens?.filter((item: any) => item.address !== 'native') || [];

        let isUpdate = false;

        for (let i = 0; i < _data.length; i++) {
          const item = _data[i];
          const isExist = customTokenList.find((item2: any) => item2.metaData.address?.toLowerCase?.() === item.address?.toLowerCase?.());
          if (!isExist) {
            const { name, symbol, image, address: tokenAddress, isCustom } = item || {};

            const data: ImportTokenInfo = {
              chainName,
              chainId,
              userAddress,
              tokenAddress,
              chainNetwork,
              isSelected: true,
              isHide: false,
              isCustom: isCustom ?? undefined,
              image,
              name,
              symbol,
              metaData: item || {},
              updateTime: '',
            };

            // set dynamicData
            data.metaData.dataSource = 'dynamicData';
            isUpdate = true;
            webWalletData.particleWallet.addImportTokenData(data);
          }
        }

        // removeImportTokenData
        const dynamicTokenList = customTokenList.filter((item: any) => item?.metaData?.dataSource == 'dynamicData');
        for (let i = 0; i < dynamicTokenList.length; i++) {
          const item = dynamicTokenList[i];
          let isExist = data.tokens?.find((o: any) => o.address?.toLowerCase?.() == item.tokenAddress?.toLowerCase?.());
          if (!isExist && item.isHide !== true) {
            isUpdate = true;
            webWalletData.particleWallet.removeImportTokenData(item);
          }
        }

        if (isUpdate) {
          setTimeout(() => {
            run();
          }, 500);
        }
      }
    });
  };

  useEffect(() => {
    if (webWalletData.isLogin) {
      getCachedTransactions();
    }
  }, [webWalletData.isLogin, webWalletData.address, webWalletData.chainId, webWalletData.chainName]);

  return { loading, data: impoertTokenList, run, runAsync, updataImpoertTokenList };
};

export default useImpoertTokenList;
